import React, { useState, useEffect } from "react";
import { supabase } from "../../supabaseClient";
import LeadsTable from "./components/LeadsTable";
import FilterBar from "./components/FilterBar";
import TaskFilterModal from "./components/TaskFilterModal";
import useLeads from "./hooks/useLeads";
import useFilters from "./hooks/useFilters";
import { GoogleContactsProvider } from "./contexts/GoogleContactsContext";

const LeadsModular = () => {
  const [session, setSession] = useState(null);
  const [authorized, setAuthorized] = useState(true); // Default to true for now
  const [taskFilterModalOpen, setTaskFilterModalOpen] = useState(false);
  const [debugMode, setDebugMode] = useState(false);

  const { 
    filters, 
    setFilters, 
    clearFilters,
    sortConfig, 
    setSortConfig,
    filterLeads,
    sortLeads
  } = useFilters();

  const { 
    leads, 
    isLoading,
    commonTaskNames,
    updateStatus,
    updateLeadPriority,
    getHighestPriority,
    toggleTaskComplete,
    updateDetails,
    handleDetailsChange,
    editedDetails,
    setEditedDetails,
    editingTasks,
    setEditingTasks,
    addTask,
    saveTaskChanges,
    updateProviders,
    editingProviders,
    setEditingProviders
  } = useLeads(true);

  // Apply filters and sorting directly here instead of in the component props
  const filteredAndSortedLeads = sortLeads(filterLeads(leads || []));

  useEffect(() => {
    const checkAuthorization = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setSession(session);
      // Allow all users for debugging
      const authorizedEmails = ["ptander@gmail.com", "afterimagejustice@gmail.com"];
      // Skip email check for now
      // setAuthorized(authorizedEmails.includes(session?.user?.email));
    };

    checkAuthorization();
  }, []);

  if (!authorized) {
    return <div className="w-full px-4 py-8">Not authorized to view this page.</div>;
  }

  return (
    <GoogleContactsProvider>
      <div className="w-full px-4 py-6" style={{ maxWidth: '100vw' }}>
        <div className="mb-6">
          <h1 className="text-2xl font-bold text-gray-800">Leads</h1>
        </div>

        <FilterBar 
          filters={filters} 
          setFilters={setFilters} 
          setTaskFilterModalOpen={setTaskFilterModalOpen}
        />

        <div className="mt-6 bg-white rounded-lg shadow">
          <div className="w-full">
            <LeadsTable 
              leads={filteredAndSortedLeads}
              sortConfig={sortConfig} 
              onSort={(field) => {
                setSortConfig(prev => ({
                  field,
                  direction: prev.field === field && prev.direction === 'desc' ? 'asc' : 'desc'
                }));
              }}
              filters={filters}
              commonTaskNames={commonTaskNames}
              updateStatus={updateStatus}
              updateLeadPriority={updateLeadPriority}
              getHighestPriority={getHighestPriority}
              toggleTaskComplete={toggleTaskComplete}
              updateDetails={updateDetails}
              handleDetailsChange={handleDetailsChange}
              editedDetails={editedDetails}
              setEditedDetails={setEditedDetails}
              editingTasks={editingTasks}
              setEditingTasks={setEditingTasks}
              addTask={addTask}
              saveTaskChanges={saveTaskChanges}
              updateProviders={updateProviders}
              editingProviders={editingProviders}
              setEditingProviders={setEditingProviders}
            />
          </div>
        </div>
        
        <TaskFilterModal
          open={taskFilterModalOpen}
          setOpen={setTaskFilterModalOpen}
          filters={filters}
          setFilters={setFilters}
          leads={leads}
        />
        
        {/* Feedback message container */}
        <div id="feedback-message" className="hidden fixed bottom-4 right-4 z-50 px-4 py-2 rounded shadow-md"></div>
        
        {/* Debug mode toggle */}
        <button 
          onClick={() => setDebugMode(!debugMode)}
          className="text-xs text-gray-500 hover:text-gray-700 underline fixed bottom-4 left-4"
        >
          {debugMode ? 'Hide Debug Info' : 'Show Debug Info'}
        </button>
        
        {debugMode && (
          <div className="text-xs bg-gray-100 p-4 my-2 rounded fixed bottom-10 left-4 z-50 max-w-sm overflow-auto max-h-80">
            <h3 className="font-bold mb-2">Debug Information</h3>
            <div>Total leads: {leads ? leads.length : 0}</div>
            <div>Filtered leads: {filterLeads(leads || []).length}</div>
            <div>Loading state: {isLoading ? 'Loading' : 'Not loading'}</div>
            <div>Active filters: {JSON.stringify(filters)}</div>
            <div>Sort config: {JSON.stringify(sortConfig)}</div>
            <div>Common task names: {JSON.stringify(commonTaskNames)}</div>
          </div>
        )}
      </div>
    </GoogleContactsProvider>
  );
};

export default LeadsModular; 