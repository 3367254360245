import { useState, useEffect } from 'react';
import { supabase } from '../../../supabaseClient';

const useLeads = (shouldFetch = true) => {
  const [leads, setLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editedDetails, setEditedDetails] = useState({});
  const [editingTasks, setEditingTasks] = useState(null);
  const [editingProviders, setEditingProviders] = useState(null);
  const [commonTaskNames, setCommonTaskNames] = useState([]);

  // Extract common task names from leads
  useEffect(() => {
    if (leads.length > 0) {
      const taskNames = new Set();
      leads.forEach(lead => {
        lead.tasks?.forEach(task => {
          if (task.name && task.name !== 'custom') {
            taskNames.add(task.name);
          }
        });
      });
      setCommonTaskNames(Array.from(taskNames).filter(name => name.trim() !== '').sort());
    }
  }, [leads]);

  // Fetch leads
  useEffect(() => {
    if (!shouldFetch) return;

    const fetchLeads = async () => {
      try {
        setIsLoading(true);
        setError(null);
        
        const { data, error } = await supabase
          .from('patient_cases')
          .select('*')
          .order('created_at', { ascending: false });

        if (error) throw error;
        setLeads(data);
      } catch (error) {
        console.error('Error fetching leads:', error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLeads();
  }, [shouldFetch]);

  // Helper function to get highest priority
  const getHighestPriority = (tasks) => {
    if (!tasks?.length) return 0;
    return Math.max(...tasks.map(t => t.priority));
  };

  // Update functions
  const updateStatus = async (id, newStatus) => {
    try {
      const { error } = await supabase
        .from('patient_cases')
        .update({ status: newStatus })
        .eq('id', id);

      if (error) throw error;
      
      setLeads(leads.map(lead => 
        lead.id === id ? { ...lead, status: newStatus } : lead
      ));
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const updateDetails = async (id) => {
    try {
      const newDetails = editedDetails[id];
      if (newDetails === undefined) return;

      const { error } = await supabase
        .from('patient_cases')
        .update({ details: newDetails })
        .eq('id', id);

      if (error) throw error;
      
      setLeads(leads.map(lead => 
        lead.id === id ? { ...lead, details: newDetails } : lead
      ));

      setEditedDetails(prev => {
        const newState = { ...prev };
        delete newState[id];
        return newState;
      });
    } catch (error) {
      console.error('Error updating details:', error);
    }
  };

  const handleDetailsChange = (id, value) => {
    setEditedDetails(prev => ({
      ...prev,
      [id]: value
    }));
  };

  const updateTasks = async (id, newTasks) => {
    try {
      const { error } = await supabase
        .from('patient_cases')
        .update({ tasks: newTasks })
        .eq('id', id);

      if (error) throw error;
      
      setLeads(leads.map(lead => 
        lead.id === id ? { ...lead, tasks: newTasks } : lead
      ));
    } catch (error) {
      console.error('Error updating tasks:', error);
    }
  };

  const toggleTaskComplete = (leadId, taskIndex) => {
    const lead = leads.find(l => l.id === leadId);
    if (!lead?.tasks) return;

    const newTasks = [...lead.tasks];
    newTasks[taskIndex] = {
      ...newTasks[taskIndex],
      complete: !newTasks[taskIndex].complete
    };
    updateTasks(leadId, newTasks);
  };

  const addTask = (leadId) => {
    const lead = leads.find(l => l.id === leadId);
    const newTask = {
      name: "",
      complete: false,
      priority: 1
    };
    const newTasks = [...(lead.tasks || []), newTask];
    setEditingTasks({ leadId, tasks: newTasks });
    
    // Focus the task name dropdown after a short delay to allow the component to render
    setTimeout(() => {
      const taskSelect = document.querySelector(`[data-lead-id="${leadId}"] select`);
      if (taskSelect) {
        taskSelect.focus();
      }
    }, 10);
  };

  const saveTaskChanges = (leadId) => {
    const taskData = editingTasks?.tasks
      .filter(task => {
        // Filter out empty tasks or custom tasks without names
        if (!task.name) return false;
        if (task.name === 'custom' && (!task.customName || !task.customName.trim())) return false;
        return true;
      })
      .map(task => ({
        ...task,
        name: task.name === 'custom' ? 'custom' : task.name,
        customName: task.name === 'custom' ? task.customName.trim() : undefined
      })) || [];
      
    updateTasks(leadId, taskData);
    setEditingTasks(null);
  };

  const updateProviders = async (id, newProviders) => {
    try {
      const { error } = await supabase
        .from('patient_cases')
        .update({ providers: newProviders })
        .eq('id', id);

      if (error) throw error;
      
      setLeads(leads.map(lead => 
        lead.id === id ? { ...lead, providers: newProviders } : lead
      ));
      setEditingProviders(null);
    } catch (error) {
      console.error('Error updating providers:', error);
    }
  };

  const updateLeadPriority = async (id, newPriority) => {
    try {
      const lead = leads.find(l => l.id === id);
      let newTasks = [...(lead.tasks || [])];
      
      if (newTasks.length === 0) {
        newTasks = [{ name: 'Priority Task', complete: false, priority: newPriority }];
      } else {
        const maxPriorityIndex = newTasks.reduce((maxIdx, task, idx, arr) => 
          task.priority > arr[maxIdx].priority ? idx : maxIdx, 0);
        newTasks[maxPriorityIndex] = { ...newTasks[maxPriorityIndex], priority: newPriority };
      }

      await updateTasks(id, newTasks);
    } catch (error) {
      console.error('Error updating priority:', error);
    }
  };

  return {
    leads,
    isLoading,
    error,
    commonTaskNames,
    updateStatus,
    updateLeadPriority,
    getHighestPriority,
    toggleTaskComplete,
    updateDetails,
    handleDetailsChange,
    editedDetails,
    setEditedDetails,
    editingTasks,
    setEditingTasks,
    addTask,
    saveTaskChanges,
    updateProviders,
    editingProviders,
    setEditingProviders
  };
};

export default useLeads; 