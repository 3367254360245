/**
 * Utility functions for formatting dates, phone numbers, etc.
 */

/**
 * Format a date string to a readable format
 * @param {string} dateString - ISO date string
 * @return {string} Formatted date
 */
export const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  return new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
};

/**
 * Format a date string with time to a readable format
 * @param {string} dateString - ISO date string with time
 * @return {string} Formatted date and time
 */
export const formatDateTime = (dateString) => {
  if (!dateString) return 'N/A';
  return new Date(dateString).toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });
};

/**
 * Format a phone number for display and for use in Google Voice links
 * @param {string} phone - The raw phone number
 * @return {Object} Object with formatted versions of the phone number
 */
export const formatPhoneNumber = (phone) => {
  if (!phone) return { callFormat: 'N/A', messageFormat: 'N/A' };
  // Remove all non-numeric characters
  const cleaned = phone.replace(/\D/g, '');
  // Ensure it starts with 1 for US numbers if it doesn't already
  const number = cleaned.startsWith('1') ? cleaned : `1${cleaned}`;
  return {
    messageFormat: `%2B${number}`,  // for messages (t. format)
    callFormat: `%2B${number}`      // for calls (nc format)
  };
};

/**
 * Format a calendar event date for display
 * @param {string} dateString - ISO date string or date-only string
 * @return {string} Formatted date/time for display
 */
export const formatEventDate = (dateString) => {
  if (!dateString) return '';
  
  const date = new Date(dateString);
  
  // Check if it's a date-only or date-time format
  const isDateOnly = dateString.indexOf('T') === -1;
  
  if (isDateOnly) {
    return date.toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short', 
      day: 'numeric'
    });
  }
  
  return date.toLocaleString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit'
  });
};

/**
 * Get a label for a priority level
 * @param {number} priority - Priority level (0-4)
 * @return {string} Human-readable priority label
 */
export const getPriorityLabel = (priority) => {
  switch (priority) {
    case 4: return 'Critical';
    case 3: return 'High';
    case 2: return 'Medium';
    case 1: return 'Low';
    case 0: return 'None';
    default: return 'None';
  }
};

/**
 * Get CSS classes for a priority level
 * @param {number} priority - Priority level (0-4)
 * @return {string} Tailwind CSS classes
 */
export const getPriorityClasses = (priority) => {
  switch (priority) {
    case 4: return 'bg-purple-100 text-purple-800 border-purple-200';
    case 3: return 'bg-red-100 text-red-800 border-red-200';
    case 2: return 'bg-yellow-100 text-yellow-800 border-yellow-200';
    case 1: return 'bg-green-100 text-green-800 border-green-200';
    case 0:
    default: return 'bg-gray-100 text-gray-800 border-gray-200';
  }
};

/**
 * Create a Google Calendar link for a lead
 * @param {Object} lead - Lead data
 * @returns {string} - Google Calendar URL
 */
export const createCalendarLink = (lead) => {
  // Get lead's full name
  const fullName = `${lead.first_name || ''} ${lead.last_name || ''}`.trim() || 'Lead';
  
  // Create event title
  const eventTitle = encodeURIComponent(`Call with ${fullName}`);
  
  // Create the call link for Google Voice
  const phoneCall = lead.phone ? 
    encodeURIComponent(`Call: <a href="https://voice.google.com/u/0/calls?a=nc,${formatPhoneNumber(lead.phone).callFormat}">${lead.phone}</a>`) : 
    '';
  
  // Add any details from the lead's condition or details
  const leadDetails = lead.condition ? 
    encodeURIComponent(`\n\nRegarding: ${lead.condition}`) : 
    '';
  
  // Combine the details
  const details = `${phoneCall}${leadDetails}`;
  
  // Set the location to the phone number to help with matching
  const location = lead.phone ? encodeURIComponent(lead.phone) : '';
  
  // Set default dates for 30 minute call tomorrow at 10 AM
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(10, 0, 0, 0);
  
  const endTime = new Date(tomorrow);
  endTime.setMinutes(endTime.getMinutes() + 30);
  
  // Format dates for Google Calendar (YYYYMMDDTHHMMSS format)
  const formatDate = (date) => {
    return date.getFullYear() +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      ('0' + date.getDate()).slice(-2) +
      'T' +
      ('0' + date.getHours()).slice(-2) +
      ('0' + date.getMinutes()).slice(-2) +
      ('0' + date.getSeconds()).slice(-2);
  };
  
  const startDate = formatDate(tomorrow);
  const endDate = formatDate(endTime);
  
  // Construct the calendar URL with location parameter
  return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${eventTitle}&details=${details}&location=${location}&dates=${startDate}/${endDate}`;
}; 