import { useState } from 'react';

export const useFilters = () => {
  const [sortConfig, setSortConfig] = useState({ field: 'created_at', direction: 'desc' });
  const [filters, setFilters] = useState({
    dateRange: { start: '', end: '' },
    searchText: '',
    priority: 'all', // 'all', '1', '2', '3'
    specificTasks: [], // Filter for specific task status
    status: 'all' // Status filter: 'all', 'new', 'pending', etc.
  });

  // Clear all filters
  const clearFilters = () => {
    setFilters({
      dateRange: { start: '', end: '' },
      searchText: '',
      priority: 'all',
      specificTasks: [],
      status: 'all'
    });
  };

  // Handle sorting
  const handleSort = (field) => {
    setSortConfig(prev => ({
      field,
      direction: prev.field === field && prev.direction === 'desc' ? 'asc' : 'desc'
    }));
  };

  // Filter leads based on current filters
  const filterLeads = (leads) => {
    if (!leads || !Array.isArray(leads)) return [];
    
    return leads.filter(lead => {
      // Search filter
      if (filters.searchText && !matchesSearch(lead, filters.searchText)) {
        return false;
      }
      
      // Status filter
      if (filters.status !== 'all' && lead.status !== filters.status) {
        return false;
      }
      
      // Priority filter
      if (filters.priority !== 'all') {
        const leadPriority = getLeadPriority(lead);
        if (leadPriority !== parseInt(filters.priority)) {
          return false;
        }
      }
      
      // Date range filter
      if (filters.dateRange.start && new Date(lead.created_at) < new Date(filters.dateRange.start)) {
        return false;
      }
      
      if (filters.dateRange.end) {
        const endDateObj = new Date(filters.dateRange.end);
        endDateObj.setHours(23, 59, 59, 999);
        if (new Date(lead.created_at) > endDateObj) {
          return false;
        }
      }

      // Specific task status filters
      if (filters.specificTasks && filters.specificTasks.length > 0) {
        for (const taskFilter of filters.specificTasks) {
          const matchingTask = lead.tasks?.find(t => 
            t.name.toLowerCase() === taskFilter.name.toLowerCase()
          );
          
          // If task doesn't exist or completion status doesn't match filter requirement
          if (!matchingTask || matchingTask.complete !== taskFilter.complete) {
            return false;
          }
        }
      }
      
      return true;
    });
  };

  // Sort leads based on current sort config
  const sortLeads = (leads) => {
    if (!leads || !Array.isArray(leads)) return [];
    
    return [...leads].sort((a, b) => {
      let aValue, bValue;
      
      // Handle special cases
      if (sortConfig.field === 'priority') {
        aValue = getLeadPriority(a);
        bValue = getLeadPriority(b);
      } else {
        aValue = a[sortConfig.field];
        bValue = b[sortConfig.field];
      }
      
      // Handle date comparisons
      if (sortConfig.field === 'created_at' || sortConfig.field === 'updated_at' || sortConfig.field === 'incident_date') {
        aValue = new Date(aValue || 0).getTime();
        bValue = new Date(bValue || 0).getTime();
      } else if (sortConfig.field === 'tasks') {
        // Count incomplete tasks for task sorting
        aValue = a.tasks?.filter(t => !t.complete).length || 0;
        bValue = b.tasks?.filter(t => !t.complete).length || 0;
      }
      
      // String comparisons
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }
      
      // Null/undefined handling
      if (aValue === null || aValue === undefined) return 1;
      if (bValue === null || bValue === undefined) return -1;
      
      // Actual comparison
      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
  };

  // Helper functions
  const matchesSearch = (lead, searchTerm) => {
    if (!searchTerm || !lead) return true;
    searchTerm = searchTerm.toLowerCase();
    
    // List of fields to search in
    const fieldsToSearch = [
      'first_name',
      'last_name',
      'email',
      'phone',
      'condition',
      'details'
    ];
    
    return fieldsToSearch.some(field => {
      const value = lead[field];
      return value && value.toString().toLowerCase().includes(searchTerm);
    });
  };

  const getLeadPriority = (lead) => {
    if (!lead?.tasks || lead.tasks.length === 0) return 0;
    return Math.max(...lead.tasks.map(task => parseInt(task.priority || 0)));
  };

  return {
    filters,
    setFilters,
    clearFilters,
    sortConfig,
    setSortConfig,
    handleSort,
    filterLeads,
    sortLeads
  };
};

export default useFilters; 