import React, { useState } from "react";

const TaskFilterModal = ({ open, setOpen, filters, setFilters, leads }) => {
  const [customTaskName, setCustomTaskName] = useState("");
  
  if (!open) return null;

  const handleNestedFilterChange = (key, value) => {
    setFilters(prev => ({
      ...prev,
      specificTasks: key === 'specificTasks' ? value : prev.specificTasks,
      taskFilter: key === 'taskFilter' ? {
        ...prev.taskFilter,
        ...value
      } : prev.taskFilter
    }));
  };

  // Get all unique tasks from all leads
  const getAllTasks = () => {
    const taskSet = new Set();
    
    leads.forEach(lead => {
      if (lead.tasks && Array.isArray(lead.tasks)) {
        lead.tasks.forEach(task => {
          if (task.name) {
            taskSet.add(task.name);
          }
        });
      }
    });
    
    return Array.from(taskSet).sort();
  };

  const uniqueTasks = getAllTasks();

  const addTaskFilter = (taskName, complete) => {
    if (!taskName.trim()) return;
    
    const newTaskFilter = {
      name: taskName,
      complete: complete
    };
    
    // Check if this filter already exists
    const exists = filters.specificTasks.some(t => 
      t.name === taskName && t.complete === complete
    );
    
    if (exists) return; // Don't add duplicates
    
    // Add to the specific tasks filter
    setFilters(prev => ({
      ...prev,
      specificTasks: [...prev.specificTasks, newTaskFilter]
    }));
    
    // Clear the custom task input if it was used
    if (taskName === customTaskName) {
      setCustomTaskName("");
    }
  };

  const removeTaskFilter = (index) => {
    setFilters(prev => ({
      ...prev,
      specificTasks: prev.specificTasks.filter((_, i) => i !== index)
    }));
  };

  return (
    <div className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center">
      <div 
        className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
        onClick={() => setOpen(false)}
      ></div>
      
      <div className="relative bg-white rounded-lg max-w-md w-full mx-auto p-6 shadow-xl">
        <div className="absolute top-0 right-0 pt-4 pr-4">
          <button
            type="button"
            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
            onClick={() => setOpen(false)}
          >
            <span className="sr-only">Close</span>
            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        
        <div className="mt-3 text-center sm:mt-0 sm:text-left">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Task Filters</h3>
          
          <div className="mt-4 space-y-4">
            {/* Current Task Filters */}
            {filters.specificTasks.length > 0 && (
              <div>
                <h4 className="font-medium text-sm text-gray-700 mb-2">Current Task Filters:</h4>
                <div className="space-y-2">
                  {filters.specificTasks.map((taskFilter, index) => (
                    <div key={index} className="flex items-center justify-between p-2 bg-gray-50 rounded">
                      <span className="text-sm">
                        {taskFilter.name} - {taskFilter.complete ? 'Completed' : 'Not Completed'}
                      </span>
                      <button
                        type="button"
                        className="text-red-500 hover:text-red-700"
                        onClick={() => removeTaskFilter(index)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
            
            {/* Custom Task Entry */}
            <div className="border-t border-gray-200 pt-4">
              <h4 className="font-medium text-sm text-gray-700 mb-2">Add Custom Task Filter:</h4>
              <div className="flex items-center space-x-2">
                <input
                  type="text"
                  className="flex-1 border border-gray-300 rounded-md px-3 py-2 text-sm focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter custom task name..."
                  value={customTaskName}
                  onChange={(e) => setCustomTaskName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && customTaskName.trim()) {
                      addTaskFilter(customTaskName.trim(), false);
                    }
                  }}
                />
                <div className="flex space-x-1">
                  <button
                    type="button"
                    className="px-2 py-1 text-xs bg-green-100 text-green-800 rounded hover:bg-green-200 whitespace-nowrap"
                    onClick={() => addTaskFilter(customTaskName.trim(), true)}
                    disabled={!customTaskName.trim()}
                  >
                    + Completed
                  </button>
                  <button
                    type="button"
                    className="px-2 py-1 text-xs bg-yellow-100 text-yellow-800 rounded hover:bg-yellow-200 whitespace-nowrap"
                    onClick={() => addTaskFilter(customTaskName.trim(), false)}
                    disabled={!customTaskName.trim()}
                  >
                    + Not Completed
                  </button>
                </div>
              </div>
            </div>
            
            {/* Add Task Filter from Existing */}
            <div className="border-t border-gray-200 pt-4">
              <h4 className="font-medium text-sm text-gray-700 mb-2">Add Existing Task Filter:</h4>
              
              {uniqueTasks.length > 0 ? (
                <div className="space-y-2 max-h-[200px] overflow-y-auto">
                  {uniqueTasks.map((taskName, index) => (
                    <div key={index} className="flex items-center justify-between">
                      <span className="text-sm">{taskName}</span>
                      <div className="space-x-2">
                        <button
                          type="button"
                          className="px-2 py-1 text-xs bg-green-100 text-green-800 rounded hover:bg-green-200"
                          onClick={() => addTaskFilter(taskName, true)}
                        >
                          + Completed
                        </button>
                        <button
                          type="button"
                          className="px-2 py-1 text-xs bg-yellow-100 text-yellow-800 rounded hover:bg-yellow-200"
                          onClick={() => addTaskFilter(taskName, false)}
                        >
                          + Not Completed
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-sm text-gray-500">No tasks found in the system.</div>
              )}
            </div>
          </div>
        </div>
        
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => setOpen(false)}
          >
            Apply Filters
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => {
              setFilters(prev => ({
                ...prev,
                specificTasks: []
              }));
              setCustomTaskName("");
              setOpen(false);
            }}
          >
            Reset Task Filters
          </button>
        </div>
      </div>
    </div>
  );
};

export default TaskFilterModal; 