import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { supabase } from "./supabaseClient";
import { pdfjs } from "react-pdf";
import LandingPage from "./pages/landing/LandingPage";
import Home from "./pages/Home";
import Cases from "./pages/Cases";
import CaseDetails from "./pages/CaseDetails/CaseDetails";
import CaseForm from "./pages/CaseForm";
import Analysis from "./pages/analysis/Analysis";
import Auth from "./components/Auth";
import AuthCallback from "./components/AuthCallback";
import NotFound from "./pages/NotFound";
import Navigation from "./components/Navigation";
import AuthAdmin from "./pages/AuthAdmin";
import Leads from "./pages/Leads";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// Layout component
const Layout = ({ children, session }) => {
  // Check if the current path is leads - to apply full width
  const location = window.location.pathname;
  const isLeadsPage = location.includes('/leads');

  return (
    <div className="bg-slate-50">
      <Navigation session={session} />
      <div className={`${isLeadsPage ? 'w-full' : 'container max-w-full'} pb-4`}>
        {children}
      </div>
    </div>
  );
};

// Protected route component
const PrivateRoute = ({ children }) => {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    async function getSession() {
      const {
        data: { session },
        error,
      } = await supabase.auth.getSession();
      if (mounted) {
        if (error) {
          console.error("Error fetching session:", error);
        } else {
          setSession(session);
        }
        setLoading(false);
      }
    }

    getSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        if (mounted) {
          setSession(session);
          setLoading(false);
        }
      },
    );

    return () => {
      mounted = false;
      if (authListener && authListener.subscription) {
        authListener.subscription.unsubscribe();
      }
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!session) {
    return <Navigate to="/auth" replace />;
  }

  return children;
};

const App = () => {
  const [session, setSession] = useState(null);

  useEffect(() => {
    let mounted = true;

    async function getSession() {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (mounted) {
        setSession(session);
      }
    }

    getSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        if (mounted) {
          setSession(session);
        }
      },
    );

    return () => {
      mounted = false;
      if (authListener && authListener.subscription) {
        authListener.subscription.unsubscribe();
      }
    };
  }, []);

  return (
    <Router>
      <Layout session={session}>
        <Routes>
          <Route path="/" element={<LandingPage session={session} />} />
          <Route
            path="/auth"
            element={!session ? <Auth /> : <Navigate to="/home" replace />}
          />
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/cases"
            element={
              <PrivateRoute>
                <Cases />
              </PrivateRoute>
            }
          />
          <Route
            path="/cases/:id"
            element={
              <PrivateRoute>
                <CaseDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-case"
            element={
              <PrivateRoute>
                <CaseForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/analysis/:id"
            element={
              <PrivateRoute>
                <Analysis />
              </PrivateRoute>
            }
          />
          <Route path="/auth/callback" element={<AuthCallback />} />
          <Route
            path="/leads"
            element={
              <PrivateRoute>
                <Leads />
              </PrivateRoute>
            }
          />
          <Route
            path="/auth-admin"
            element={
              <PrivateRoute>
                <AuthAdmin />
              </PrivateRoute>
            }
          />
          <Route
            path="*"
            element={
              <PrivateRoute>
                <NotFound />
              </PrivateRoute>
            }
          />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
