import React from "react";

const FilterBar = ({ filters, setFilters, setTaskFilterModalOpen }) => {
  const handleFilterChange = (key, value) => {
    setFilters(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const handleNestedFilterChange = (parentKey, key, value) => {
    setFilters(prev => ({
      ...prev,
      [parentKey]: {
        ...prev[parentKey],
        [key]: value
      }
    }));
  };

  return (
    <div className="bg-white p-4 mb-6 rounded-lg shadow-sm">
      <div className="flex flex-wrap gap-4 items-center">
        {/* Search field */}
        <div className="flex-grow min-w-[200px]">
          <label htmlFor="search" className="sr-only">Search</label>
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
              </svg>
            </div>
            <input
              type="text"
              id="search"
              className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md"
              placeholder="Search leads..."
              value={filters.searchText}
              onChange={(e) => handleFilterChange("searchText", e.target.value)}
            />
          </div>
        </div>

        {/* Status filter */}
        <div className="w-48">
          <label htmlFor="status" className="block text-xs font-medium text-gray-700 mb-1">Status</label>
          <select
            id="status"
            className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            value={filters.status}
            onChange={(e) => handleFilterChange("status", e.target.value)}
          >
            <option value="all">All Statuses</option>
            <option value="new">New</option>
            <option value="pending">Pending</option>
            <option value="contacted">Contacted</option>
            <option value="confirmed">Confirmed</option>
            <option value="qualified">Qualified</option>
            <option value="disqualified">Disqualified</option>
            <option value="converted">Converted</option>
          </select>
        </div>

        {/* Priority filter */}
        <div className="w-40">
          <label htmlFor="priority" className="block text-xs font-medium text-gray-700 mb-1">Priority</label>
          <select
            id="priority"
            className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            value={filters.priority}
            onChange={(e) => handleFilterChange("priority", e.target.value)}
          >
            <option value="all">All Priorities</option>
            <option value="4">Critical</option>
            <option value="3">High</option>
            <option value="2">Medium</option>
            <option value="1">Low</option>
            <option value="0">None</option>
          </select>
        </div>

        {/* Task filter button */}
        <div>
          <label className="block text-xs font-medium text-gray-700 mb-1">Task Filters</label>
          <button
            type="button"
            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={() => setTaskFilterModalOpen(true)}
          >
            <svg className="mr-1.5 h-4 w-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clipRule="evenodd" />
            </svg>
            Task Filters
          </button>
        </div>

        {/* Date range pickers */}
        <div className="flex space-x-2">
          <div>
            <label htmlFor="startDate" className="block text-xs font-medium text-gray-700 mb-1">Start Date</label>
            <input
              type="date"
              id="startDate"
              className="border rounded px-2 py-1 text-sm"
              value={filters.dateRange.start}
              onChange={(e) => handleNestedFilterChange("dateRange", "start", e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="endDate" className="block text-xs font-medium text-gray-700 mb-1">End Date</label>
            <input
              type="date"
              id="endDate"
              className="border rounded px-2 py-1 text-sm"
              value={filters.dateRange.end}
              onChange={(e) => handleNestedFilterChange("dateRange", "end", e.target.value)}
            />
          </div>
        </div>

        {/* Reset filters button */}
        <div className="mt-2">
          <button
            type="button"
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            onClick={() => setFilters({
              searchText: "",
              status: "all",
              priority: "all",
              dateRange: { start: '', end: '' },
              specificTasks: []
            })}
          >
            Reset Filters
          </button>
        </div>
      </div>

      {/* Active filters indicators */}
      {(filters.searchText || 
        filters.status !== "all" || 
        filters.priority !== "all" || 
        filters.dateRange.start || 
        filters.dateRange.end ||
        filters.specificTasks.length > 0) && (
        <div className="mt-3 flex flex-wrap gap-2">
          <span className="text-xs text-gray-500">Active filters:</span>
          
          {filters.searchText && (
            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
              Search: {filters.searchText}
              <button 
                type="button" 
                className="ml-1 text-blue-500 hover:text-blue-700"
                onClick={() => handleFilterChange("searchText", "")}
              >
                &times;
              </button>
            </span>
          )}
          
          {filters.status !== "all" && (
            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-100 text-purple-800">
              Status: {filters.status.replace('_', ' ')}
              <button 
                type="button" 
                className="ml-1 text-purple-500 hover:text-purple-700"
                onClick={() => handleFilterChange("status", "all")}
              >
                &times;
              </button>
            </span>
          )}
          
          {filters.priority !== "all" && (
            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
              Priority: {filters.priority}
              <button 
                type="button" 
                className="ml-1 text-yellow-500 hover:text-yellow-700"
                onClick={() => handleFilterChange("priority", "all")}
              >
                &times;
              </button>
            </span>
          )}
          
          {filters.dateRange.start && (
            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
              From: {filters.dateRange.start}
              <button 
                type="button" 
                className="ml-1 text-green-500 hover:text-green-700"
                onClick={() => handleNestedFilterChange("dateRange", "start", "")}
              >
                &times;
              </button>
            </span>
          )}
          
          {filters.dateRange.end && (
            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
              To: {filters.dateRange.end}
              <button 
                type="button" 
                className="ml-1 text-green-500 hover:text-green-700"
                onClick={() => handleNestedFilterChange("dateRange", "end", "")}
              >
                &times;
              </button>
            </span>
          )}
          
          {filters.specificTasks.length > 0 && filters.specificTasks.map((taskFilter, idx) => (
            <span key={idx} className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-indigo-100 text-indigo-800">
              Task: {taskFilter.name} ({taskFilter.complete ? 'Completed' : 'Pending'})
              <button 
                type="button" 
                className="ml-1 text-indigo-500 hover:text-indigo-700"
                onClick={() => {
                  setFilters(prev => ({
                    ...prev,
                    specificTasks: prev.specificTasks.filter((_, i) => i !== idx)
                  }));
                }}
              >
                &times;
              </button>
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterBar; 