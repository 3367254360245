import React, { useState, useEffect, useMemo } from "react";
import { Download, Filter, Search, ArrowRight, Clock, AlertCircle, ChevronDown, ChevronUp } from "lucide-react";
import { fetchAnalysis, fetchPdfUrl, downloadFile } from "../../services/api";

// Reuse helpers from appeal_lien_claims.js
const formatCurrency = (amount) => {
  if (!amount && amount !== 0) return "N/A";
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
};

const formatDate = (date) => {
  if (!date) return "N/A";
  const dateObj = new Date(date);
  return !isNaN(dateObj) ? dateObj.toLocaleDateString('en-US') : date;
};

const extractPlainText = (content) => {
  if (!content) return "";
  try {
    const contentObj = typeof content === 'string' ? JSON.parse(content) : content;
    return contentObj.content || content;
  } catch (e) {
    return content;
  }
};

// Case Metadata Card Component
const CaseMetadataCard = ({ metadata, codeDescriptions }) => {
  const [expanded, setExpanded] = useState(false);
  
  const formatDiagnosisCodes = (codes) => {
    if (!codes || codes.length === 0) return "None specified";
    
    return codes.map(code => {
      const codeValue = typeof code === 'object' ? code.code : code;
      const description = codeDescriptions?.[codeValue] || '';
      return (
        <div key={codeValue} className="mb-1">
          <span className="font-medium">{codeValue}</span>
          {description && <span className="text-gray-600"> - {description}</span>}
        </div>
      );
    });
  };
  
  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
      <div className="p-6 border-b border-gray-100">
        <h2 className="text-xl font-semibold text-gray-900">Subrogation Case Information</h2>
      </div>
      
      <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <h3 className="text-sm uppercase text-gray-500 font-medium mb-2">Member Information</h3>
          <p className="text-gray-900 font-medium">{metadata.name || "Not specified"}</p>
          {metadata.member_id && (
            <p className="text-gray-600 text-sm mt-1">
              <span className="font-medium">Member ID:</span> {metadata.member_id}
            </p>
          )}
        </div>
        
        <div>
          <h3 className="text-sm uppercase text-gray-500 font-medium mb-2">Case Details</h3>
          <p className="text-gray-600 text-sm">
            <span className="font-medium">Case ID:</span> {metadata.case_id || "Not specified"}
          </p>
          <p className="text-gray-600 text-sm mt-1">
            <span className="font-medium">Case Type:</span> {metadata.case_type || "Not specified"}
          </p>
          <p className="text-gray-600 text-sm mt-1">
            <span className="font-medium">Date of Incident:</span> {formatDate(metadata.date_of_incident)}
          </p>
        </div>
      </div>
      
      <div className="px-6 pb-6">
        <div className="mb-2">
          <button
            onClick={() => setExpanded(!expanded)}
            className="flex items-center text-blue-600 hover:text-blue-800 font-medium focus:outline-none"
          >
            {expanded ? (
              <>
                <ChevronUp className="w-4 h-4 mr-1" />
                Hide Diagnosis Codes
              </>
            ) : (
              <>
                <ChevronDown className="w-4 h-4 mr-1" />
                Show Diagnosis Codes
              </>
            )}
          </button>
        </div>
        
        {expanded && (
          <div className="mt-3 p-4 bg-gray-50 rounded-lg">
            <h3 className="text-sm uppercase text-gray-500 font-medium mb-2">Reported Diagnosis Codes</h3>
            {formatDiagnosisCodes(metadata.reported_diagnosis_codes)}
          </div>
        )}
      </div>
    </div>
  );
};

// Case Statistics Summary
const CaseStatsSummary = ({ stats }) => {
  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
      <div className="p-6 border-b border-gray-100">
        <h2 className="text-xl font-semibold text-gray-900">Subrogation Summary</h2>
      </div>
      
      <div className="p-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        <div>
          <h3 className="text-sm uppercase text-gray-500 font-medium mb-1">Total Claims</h3>
          <p className="text-2xl font-semibold text-gray-900">{stats.total_claims || 0}</p>
        </div>
        
        <div>
          <h3 className="text-sm uppercase text-gray-500 font-medium mb-1">Total Billed</h3>
          <p className="text-2xl font-semibold text-gray-900">{formatCurrency(stats.total_billed)}</p>
        </div>
        
        <div>
          <h3 className="text-sm uppercase text-gray-500 font-medium mb-1">Total Paid</h3>
          <p className="text-2xl font-semibold text-gray-900">{formatCurrency(stats.total_paid)}</p>
        </div>
        
        <div>
          <h3 className="text-sm uppercase text-gray-500 font-medium mb-1">
            <span className="text-red-500">Unrelated Claims</span>
          </h3>
          <p className="text-2xl font-semibold text-gray-900">{stats.unrelated_claims_count || 0}</p>
          <p className="text-gray-600">{formatCurrency(stats.unrelated_claims_total)}</p>
        </div>
      </div>
    </div>
  );
};

// Appeal Letter Section
const AppealLetterSection = ({ letter }) => {
  const [expanded, setExpanded] = useState(false);
  const plainLetter = extractPlainText(letter);
  
  const displayText = expanded ? plainLetter : plainLetter.slice(0, 500) + (plainLetter.length > 500 ? '...' : '');
  
  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
      <div className="p-6 border-b border-gray-100">
        <h2 className="text-xl font-semibold text-gray-900">Subrogation Appeal Letter</h2>
      </div>
      
      <div className="p-6">
        <div className="prose prose-blue max-w-none whitespace-pre-wrap">
          {displayText.split('\n').map((line, i) => (
            <React.Fragment key={i}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </div>
        
        {plainLetter.length > 500 && (
          <button
            onClick={() => setExpanded(!expanded)}
            className="mt-4 text-blue-600 hover:text-blue-800 font-medium focus:outline-none flex items-center"
          >
            {expanded ? (
              <>Show Less <ChevronUp className="ml-1 w-4 h-4" /></>
            ) : (
              <>Show Full Letter <ChevronDown className="ml-1 w-4 h-4" /></>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

// Claims Analysis Section
const ClaimsAnalysisSection = ({ 
  claims, 
  claimsAnalysis, 
  unrelatedClaims, 
  codeDescriptions,
  chronologyEvents,
  pdfUrl,
  chronologyPdfUrl,
  pagesPerChunk
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('all'); // 'all', 'disputed', 'related'

  // Create a map of unrelated codes for each claim
  const unrelatedCodesMap = {};
  unrelatedClaims.forEach(claim => {
    const key = `${claim.provider}-${claim.dates[0]}-${claim.dates[1]}`;
    unrelatedCodesMap[key] = claim.codes;
  });

  // Filter and sort claims
  const filteredClaims = claims.filter(claim => {
    const key = `${claim.provider}-${claim.dates[0]}-${claim.dates[1]}`;
    const unrelatedCodes = unrelatedCodesMap[key] || [];
    
    // Apply search filter
    const matchesSearch = searchTerm === '' || 
      claim.provider.toLowerCase().includes(searchTerm.toLowerCase()) ||
      claim.claim_number?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      claim.member_id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      claim.codes.some(code => {
        // Check if code is a string or an object
        const codeValue = typeof code === 'string' ? code : (code.code || '');
        return codeValue.toLowerCase().includes(searchTerm.toLowerCase());
      });

    // Apply type filter
    const matchesType = filterType === 'all' ||
      (filterType === 'disputed' && unrelatedCodes.length > 0) ||
      (filterType === 'related' && unrelatedCodes.length === 0);

    return matchesSearch && matchesType;
  });

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
      <div className="p-6 border-b border-gray-100">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">Claims Analysis</h2>
        
        <div className="flex flex-wrap gap-4">
          {/* Search */}
          <div className="relative flex-grow max-w-md">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Search className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              placeholder="Search claims by provider, code or claim #"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          
          {/* Filter dropdown */}
          <div className="relative inline-block">
            <div className="flex">
              <button
                className={`inline-flex justify-center w-full px-4 py-2 text-sm font-medium rounded-md border ${
                  filterType !== 'all' ? 'bg-blue-50 text-blue-700 border-blue-300' : 'bg-white text-gray-700 border-gray-300'
                } focus:outline-none focus:ring-1 focus:ring-blue-500`}
                onClick={() => setFilterType('all')}
              >
                All Claims
              </button>
              <button
                className={`inline-flex justify-center w-full px-4 py-2 text-sm font-medium rounded-md border ${
                  filterType === 'disputed' ? 'bg-red-50 text-red-700 border-red-300' : 'bg-white text-gray-700 border-gray-300'
                } focus:outline-none focus:ring-1 focus:ring-red-500 ml-2`}
                onClick={() => setFilterType('disputed')}
              >
                <AlertCircle className="mr-2 h-4 w-4" /> Disputed
              </button>
              <button
                className={`inline-flex justify-center w-full px-4 py-2 text-sm font-medium rounded-md border ${
                  filterType === 'related' ? 'bg-green-50 text-green-700 border-green-300' : 'bg-white text-gray-700 border-gray-300'
                } focus:outline-none focus:ring-1 focus:ring-green-500 ml-2`}
                onClick={() => setFilterType('related')}
              >
                Related Only
              </button>
            </div>
          </div>
        </div>
      </div>
      
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Provider/Dates
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Claim Info
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Diagnosis Codes
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Amount
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredClaims.map((claim, index) => {
              const key = `${claim.provider}-${claim.dates[0]}-${claim.dates[1]}`;
              const unrelatedCodes = unrelatedCodesMap[key] || [];
              const isDisputed = unrelatedCodes.length > 0;
              
              return (
                <ClaimRow 
                  key={index}
                  claim={claim} 
                  isDisputed={isDisputed} 
                  unrelatedCodes={unrelatedCodes}
                  codeDescriptions={codeDescriptions}
                  claimsAnalysis={claimsAnalysis?.find(c => 
                    c.provider === claim.provider && 
                    c.from_date === claim.dates[0] &&
                    c.to_date === claim.dates[1]
                  )}
                  chronologyEvents={chronologyEvents}
                />
              );
            })}
            
            {filteredClaims.length === 0 && (
              <tr>
                <td colSpan="5" className="px-6 py-4 text-center text-gray-500 italic">
                  No claims matching your criteria
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

// Individual Claim Row Component
const ClaimRow = ({ claim, isDisputed, unrelatedCodes, codeDescriptions, claimsAnalysis, chronologyEvents }) => {
  const [expanded, setExpanded] = useState(false);
  
  // Get the analysis for this claim
  const analysis = claimsAnalysis || {};
  
  // Format the codes display
  const formatCodes = (codes, isUnrelated = false) => {
    if (!codes || codes.length === 0) return <span className="text-gray-400">None</span>;
    
    return codes.map(code => {
      // Handle both string codes and object codes
      const codeValue = typeof code === 'string' ? code : (code.code || '');
      const description = codeDescriptions?.[codeValue] || '';
      
      return (
        <div key={codeValue} className={`mb-1 ${isUnrelated ? 'text-red-600' : ''}`}>
          <span className="font-medium">{codeValue}</span>
          {description && <span className="text-gray-600"> - {description}</span>}
        </div>
      );
    });
  };
  
  return (
    <>
      <tr className={`hover:bg-gray-50 ${isDisputed ? 'bg-red-50' : ''}`}>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="flex items-center">
            <button
              onClick={() => setExpanded(!expanded)}
              className="mr-2 text-gray-400 hover:text-gray-600 focus:outline-none"
            >
              {expanded ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
            </button>
            <div>
              <div className="text-sm font-medium text-gray-900">{claim.provider}</div>
              <div className="text-xs text-gray-500">
                {formatDate(claim.dates[0])} - {formatDate(claim.dates[1])}
              </div>
            </div>
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-900">
            {claim.claim_number && <div><span className="font-medium">Claim #:</span> {claim.claim_number}</div>}
            {claim.member_id && <div><span className="font-medium">Member ID:</span> {claim.member_id}</div>}
          </div>
        </td>
        <td className="px-6 py-4">
          <div className="text-sm text-gray-900">
            {formatCodes(claim.codes.filter(code => {
              const codeValue = typeof code === 'string' ? code : (code.code || '');
              return !unrelatedCodes.some(uc => {
                const ucValue = typeof uc === 'string' ? uc : (uc.code || '');
                return ucValue === codeValue;
              });
            }))}
            {unrelatedCodes.length > 0 && (
              <div className="mt-1 text-red-600 flex items-center">
                <AlertCircle className="h-4 w-4 mr-1" /> 
                <span>{unrelatedCodes.length} disputed code(s)</span>
              </div>
            )}
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm font-medium text-gray-900">
            <div>{formatCurrency(claim.total_billed)}</div>
            {isDisputed && (
              <div className="text-red-600 font-medium">
                {formatCurrency(claim.dispute_amount)} disputed
              </div>
            )}
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
            isDisputed 
              ? 'bg-red-100 text-red-800' 
              : 'bg-green-100 text-green-800'
          }`}>
            {isDisputed ? 'Disputed' : 'Related'}
          </span>
        </td>
      </tr>
      
      {expanded && (
        <tr className={isDisputed ? 'bg-red-50' : 'bg-gray-50'}>
          <td colSpan="5" className="px-6 py-4">
            <div className="text-sm text-gray-900">
              {/* Detailed view */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Left column */}
                <div>
                  <h4 className="font-medium text-gray-900 mb-2">Claim Details</h4>
                  <div className="space-y-2">
                    <div><span className="font-medium">Provider:</span> {claim.provider}</div>
                    <div><span className="font-medium">Service Period:</span> {formatDate(claim.dates[0])} - {formatDate(claim.dates[1])}</div>
                    <div><span className="font-medium">Billed Amount:</span> {formatCurrency(claim.total_billed)}</div>
                    <div><span className="font-medium">Paid Amount:</span> {formatCurrency(claim.paid)}</div>
                    {claim.claim_number && <div><span className="font-medium">Claim Number:</span> {claim.claim_number}</div>}
                    {claim.date_paid && <div><span className="font-medium">Date Paid:</span> {formatDate(claim.date_paid)}</div>}
                  </div>
                </div>
                
                {/* Right column */}
                <div>
                  <h4 className="font-medium text-gray-900 mb-2">Diagnosis Code Analysis</h4>
                  
                  {/* Related codes */}
                  <div className="mb-3">
                    <h5 className="text-sm text-gray-700 font-medium">Related Codes:</h5>
                    <div className="pl-2 border-l-2 border-green-500">
                      {formatCodes(claim.codes.filter(code => {
                        const codeValue = typeof code === 'string' ? code : (code.code || '');
                        return !unrelatedCodes.some(uc => {
                          const ucValue = typeof uc === 'string' ? uc : (uc.code || '');
                          return ucValue === codeValue;
                        });
                      }))}
                    </div>
                  </div>
                  
                  {/* Disputed codes */}
                  {unrelatedCodes.length > 0 && (
                    <div>
                      <h5 className="text-sm text-gray-700 font-medium">Disputed Codes:</h5>
                      <div className="pl-2 border-l-2 border-red-500">
                        {formatCodes(unrelatedCodes, true)}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              
              {/* Reasoning section */}
              {analysis.code_analysis?.codes && (
                <div className="mt-4">
                  <h4 className="font-medium text-gray-900 mb-2">Analysis Reasoning</h4>
                  <div className="bg-white rounded-md border border-gray-200 p-3">
                    {Object.entries(analysis.code_analysis.codes).map(([code, analysis]) => (
                      <div key={code} className="mb-3 last:mb-0">
                        <div className="flex items-start">
                          <div className={`px-2 py-1 rounded text-xs font-semibold mr-2 ${
                            analysis.causally_related ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                          }`}>
                            {code}
                          </div>
                          <div>
                            <p className="text-sm text-gray-700">{analysis.reason}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

const AppealSubrogationClaimsDisplay = ({ content, pdfUrl, pagesPerChunk, analysisId, analysis }) => {
  // Add state for chronology data
  const [chronologyEvents, setChronologyEvents] = useState([]);
  const [chronologyLoading, setChronologyLoading] = useState(false);
  const [chronologyError, setChronologyError] = useState(null);
  // Add state for chronology PDF URL
  const [chronologyPdfUrl, setChronologyPdfUrl] = useState(null);
  
  // Extract all unique code descriptions into a centralized mapping
  const codeDescriptions = useMemo(() => {
    if (!content) return {};
    
    // Use the centralized mapping if available
    if (content.code_descriptions) {
      return content.code_descriptions;
    }
    
    // Otherwise, build it from the claims data
    if (!content.payment_data?.all_claims) return {};
    
    const descriptions = {};
    content.payment_data.all_claims.forEach(claim => {
      claim.codes.forEach(codeObj => {
        if (codeObj.code && codeObj.description) {
          // Extract just the description without the code in parentheses
          const desc = codeObj.description.replace(/^.*?\(|\)$/g, '');
          descriptions[codeObj.code] = desc;
        }
      });
    });
    
    // Add incident codes if available
    if (content.metadata?.data?.reported_diagnosis_codes) {
      content.metadata.data.reported_diagnosis_codes.forEach(code => {
        if (!descriptions[code]) {
          // Try to find the description in the claims data
          const foundClaim = content.payment_data?.all_claims?.find(claim => 
            claim.codes.some(c => c.code === code)
          );
          if (foundClaim) {
            const codeObj = foundClaim.codes.find(c => c.code === code);
            if (codeObj && codeObj.description) {
              descriptions[code] = codeObj.description.replace(/^.*?\(|\)$/g, '');
            }
          }
        }
      });
    }
    
    return descriptions;
  }, [content]);

  // Fetch chronology data when component mounts
  useEffect(() => {
    const fetchChronologyData = async () => {
      try {
        const chronologyId = analysis?.parameters?.chronology;
        if (!chronologyId) {
          console.log("No chronology ID found in analysis parameters");
          return;
        }
        
        setChronologyLoading(true);
        
        // 1. Fetch the chronology analysis data
        const chronologyData = await fetchAnalysis(chronologyId);
        
        // 2. Get the file_id from the chronology analysis
        if (chronologyData?.file_id) {
          try {
            // 3. Use fetchPdfUrl to get the URL from the file_id
            const chronologyPdf = await fetchPdfUrl(chronologyData.file_id);
            setChronologyPdfUrl(chronologyPdf);
          } catch (err) {
            console.error("Error fetching chronology PDF URL:", err);
          }
        }
        
        // 4. Set the chronology events if available
        if (chronologyData?.content?.all_events) {
          setChronologyEvents(chronologyData.content.all_events);
        } else {
          setChronologyError("No events found in chronology data");
        }
      } catch (error) {
        console.error("Error fetching chronology data:", error);
        setChronologyError(error.message);
      } finally {
        setChronologyLoading(false);
      }
    };
    
    if (analysis?.parameters?.chronology) {
      fetchChronologyData();
    }
  }, [analysis]);

  const handleReportDownload = async () => {
    try {
      if (!analysis?.report_path) {
        throw new Error('No report available for this analysis');
      }
      await downloadFile(analysis.report_path);
    } catch (error) {
      console.error('Error downloading report:', error);
      alert('Error downloading report. Please try again later.');
    }
  };

  if (!content) {
    return (
      <div className="p-8 text-center text-gray-500 bg-gray-50 rounded-lg">
        No analysis data available
      </div>
    );
  }

  // Modified error handling - show warning instead of preventing content display
  const hasRequiredData = content.metadata || content.payment_data || content.claims_analysis;
  
  // Only show a full error if no required data is available
  if (content.error && !hasRequiredData) {
    return (
      <div className="p-8 space-y-4">
        <div className="bg-red-50 border border-red-200 rounded-lg p-4">
          <h3 className="text-lg font-medium text-red-800 mb-2">Analysis Error</h3>
          <p className="text-red-700">{content.error.message}</p>
        </div>
        {content.partial_data && (
          <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
            <h3 className="text-lg font-medium text-yellow-800 mb-2">Partial Data Available</h3>
            <ul className="list-disc list-inside text-yellow-700">
              <li>Pages processed: {content.partial_data.info.num_pages_processed}</li>
              <li>Metadata found: {content.partial_data.info.metadata_found ? 'Yes' : 'No'}</li>
              <li>Table data found: {content.partial_data.info.table_data_found ? 'Yes' : 'No'}</li>
            </ul>
          </div>
        )}
      </div>
    );
  }

  const { metadata, payment_data, appeal_letter, claims_analysis } = content;

  return (
    <div className="max-w-6xl mx-auto p-6 space-y-8">
      {/* Show warning banner if there's an error but we still have data to display */}
      {content.error && (
        <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4 mb-6">
          <h3 className="text-lg font-medium text-yellow-800 mb-2">Analysis Warning</h3>
          <p className="text-yellow-700">{content.error.message}</p>
          <p className="text-yellow-700 mt-2 text-sm">Partial analysis results are displayed below.</p>
        </div>
      )}

      {/* Download Bar */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-3">
            <div className="h-10 w-10 rounded-full bg-blue-50 flex items-center justify-center">
              <Download className="h-5 w-5 text-blue-600" />
            </div>
            <div>
              <h3 className="font-medium text-gray-900">Subrogation Appeal Report</h3>
              <p className="text-sm text-gray-500">Download the complete analysis</p>
            </div>
          </div>
          <button
            onClick={handleReportDownload}
            className="inline-flex items-center gap-2 px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            <Download className="h-4 w-4" />
            <span>Download Report</span>
          </button>
        </div>
      </div>

      {/* Case Information */}
      {metadata?.data && (
        <CaseMetadataCard 
          metadata={metadata.data} 
          codeDescriptions={content.code_descriptions?.data} 
        />
      )}

      {/* Claims Summary */}
      {payment_data?.stats && <CaseStatsSummary stats={payment_data.stats} />}

      {/* Appeal Letter */}
      {appeal_letter && <AppealLetterSection letter={appeal_letter.content} />}
      
      {/* Claims Analysis section */}
      {payment_data?.all_claims && claims_analysis?.items && (
        <>
          <ClaimsAnalysisSection 
            claims={payment_data.all_claims}
            claimsAnalysis={claims_analysis.items}
            unrelatedClaims={payment_data.unrelated_claims || []}
            codeDescriptions={codeDescriptions}
            chronologyEvents={chronologyEvents}
            pdfUrl={pdfUrl}
            chronologyPdfUrl={chronologyPdfUrl}
            pagesPerChunk={pagesPerChunk}
          />
        </>
      )}
    </div>
  );
};

export default AppealSubrogationClaimsDisplay; 