import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8788/api";

// Create an axios instance with optimized settings
const apiClient = axios.create({
  baseURL: API_URL,
  timeout: 10000, // 10 seconds timeout
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add a request interceptor to ensure URLs are properly formatted
apiClient.interceptors.request.use(
  (config) => {
    // Remove any leading /api since it's already in the baseURL
    if (config.url.startsWith('/api/')) {
      config.url = config.url.substring(4);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Service for interacting with Google Contacts API
 */
const googleContactsService = {
  // Cache for API responses to reduce duplicate requests
  _cache: {
    calendarEvents: {},
    lastRequestTime: 0, // Track last request time for rate limiting
  },
  
  /**
   * Add a contact to Google Contacts using OAuth
   * @param {Object} contactData - Data for the contact to add
   * @param {string} contactData.firstName - First name
   * @param {string} contactData.lastName - Last name
   * @param {string} contactData.email - Email address
   * @param {string} contactData.phone - Phone number (optional)
   * @param {string} contactData.company - Company name (optional)
   * @param {string} contactData.notes - Additional notes (optional)
   * @returns {Promise<Object>} - Response with success status and contact info
   */
  addToGoogleContacts: async (contactData) => {
    try {
      // Use the optimized axios instance
      const response = await apiClient.post(
        `/public/google-contacts`,
        { contactData }
      );
      
      return response.data;
    } catch (error) {
      console.error('Error adding to Google Contacts:', error);
      throw new Error(error.response?.data?.error || 'Failed to add contact');
    }
  },
  
  /**
   * Check if a contact already exists in Google Contacts
   * @param {string} email - Email address to check
   * @param {string} phone - Phone number to check (optional)
   * @returns {Promise<Object>} - Response with exists status and contact info if found
   */
  checkContactExists: async (email, phone) => {
    try {
      // Build query string with both email and phone if available
      let queryString = '';
      if (email) {
        queryString += `email=${encodeURIComponent(email)}`;
      }
      if (phone) {
        queryString += queryString ? '&' : '';
        queryString += `phone=${encodeURIComponent(phone)}`;
      }
      
      // Use the optimized axios instance
      const response = await apiClient.get(
        `/public/google-contacts?${queryString}`
      );
      
      return response.data;
    } catch (error) {
      console.error('Error checking Google Contact:', error);
      throw new Error(error.response?.data?.error || 'Failed to check contact');
    }
  },

  /**
   * Check for upcoming calendar events with a lead
   * @param {Object} leadData - Data for the lead to check events for
   * @param {string} leadData.email - Email address of the lead
   * @param {string} leadData.phone - Phone number of the lead (optional)
   * @param {string} leadData.firstName - First name of the lead (optional)
   * @param {string} leadData.lastName - Last name of the lead (optional)
   * @param {number} daysAhead - Number of days ahead to check for events (default: 14)
   * @param {boolean} bypassCache - Force a fresh API request even if cached data exists
   * @returns {Promise<Object>} - Response with events data if found
   */
  checkUpcomingEvents: async (leadData, daysAhead = 14, bypassCache = false) => {
    try {
      if (!leadData.email && !leadData.phone) {
        throw new Error('Either email or phone is required to check for events');
      }
      
      // Create a cache key based on the lead data
      const cacheKey = `${leadData.email || ''}_${leadData.phone || ''}_${daysAhead}`;
      
      // Check cache first if not bypassing
      if (!bypassCache && googleContactsService._cache.calendarEvents[cacheKey]) {
        const cachedData = googleContactsService._cache.calendarEvents[cacheKey];
        const cacheTtl = 5 * 60 * 1000; // 5 minutes in milliseconds
        
        // Return cached data if it's fresh enough
        if (Date.now() - cachedData.timestamp < cacheTtl) {
          console.log('Returning cached calendar events data');
          return cachedData.data;
        }
      }
      
      // Rate limiting - ensure we don't make requests too frequently 
      const now = Date.now();
      const timeSinceLastRequest = now - googleContactsService._cache.lastRequestTime;
      const minRequestInterval = 1000; // 1 second minimum between requests
      
      if (timeSinceLastRequest < minRequestInterval) {
        // Wait until the rate limit period has passed
        await new Promise(resolve => 
          setTimeout(resolve, minRequestInterval - timeSinceLastRequest)
        );
      }
      
      // Update last request time
      googleContactsService._cache.lastRequestTime = Date.now();
      
      // Build the query string
      const queryParams = new URLSearchParams();
      
      if (leadData.email) {
        queryParams.append('email', leadData.email);
      }
      
      if (leadData.phone) {
        queryParams.append('phone', leadData.phone);
      }
      
      if (leadData.firstName) {
        queryParams.append('firstName', leadData.firstName);
      }
      
      if (leadData.lastName) {
        queryParams.append('lastName', leadData.lastName);
      }
      
      queryParams.append('daysAhead', daysAhead);
      
      // Use the optimized axios instance
      const response = await apiClient.get(
        `/public/google-calendar/events?${queryParams.toString()}`
      );
      
      // Cache the successful response
      googleContactsService._cache.calendarEvents[cacheKey] = {
        timestamp: Date.now(),
        data: response.data
      };
      
      return response.data;
    } catch (error) {
      console.error('Error checking upcoming events:', error);
      // Don't throw errors, just return empty events so UI doesn't break
      return { 
        success: false, 
        hasEvents: false, 
        events: [],
        error: error.response?.data?.error || error.message || 'Failed to check events'
      };
    }
  },

  /**
   * Fetch all upcoming calendar events for a period of time
   * @param {number} daysAhead - Number of days ahead to check for events (default: 7)
   * @returns {Promise<Object>} - Response with all events data
   */
  fetchAllCalendarEvents: async (daysAhead = 7, forceRefresh = false) => {
    try {
      const cacheKey = `all_events_${daysAhead}`;
      
      // Check cache first
      if (googleContactsService._cache.calendarEvents[cacheKey] && !forceRefresh) {
        const cachedData = googleContactsService._cache.calendarEvents[cacheKey];
        const cacheTtl = 2 * 60 * 1000; // 2 minutes in milliseconds for all events
        
        // Return cached data if it's fresh enough
        if (Date.now() - cachedData.timestamp < cacheTtl) {
          console.log('Returning cached all calendar events data');
          return cachedData.data;
        }
      }
      
      // Update last request time
      googleContactsService._cache.lastRequestTime = Date.now();
      
      // Call the endpoint with just the daysAhead parameter
      const queryParams = new URLSearchParams();
      queryParams.append('daysAhead', daysAhead);
      queryParams.append('allEvents', 'true'); // Flag to get all events
      
      console.log('Fetching all calendar events');
      const response = await apiClient.get(
        `/public/google-calendar/events?${queryParams.toString()}`
      );
      
      // Cache the successful response
      googleContactsService._cache.calendarEvents[cacheKey] = {
        timestamp: Date.now(),
        data: response.data
      };
      
      return response.data;
    } catch (error) {
      console.error('Error fetching all calendar events:', error);
      return { 
        success: false, 
        hasEvents: false, 
        events: [],
        error: error.response?.data?.error || error.message || 'Failed to fetch all events'
      };
    }
  },

  /**
   * Match calendar events to a specific lead
   * @param {Object} lead - Lead data to match events with
   * @param {Array} allEvents - Array of calendar events to match against
   * @returns {Array} - Array of matching events for this lead
   */
  matchLeadToEvents: (lead, allEvents) => {
    if (!lead || !allEvents || !Array.isArray(allEvents)) {
      return [];
    }
    
    // Only extract phone for matching, as requested by user
    // Clean the phone number to only contain digits for reliable matching
    const phone = lead.phone ? lead.phone.replace(/\D/g, '') : null;
    
    // No phone number means no way to match reliably
    if (!phone) {
      return [];
    }
    
    // Filter events that match this lead - ONLY match by phone number in location
    return allEvents.filter(event => {
      // Only check the location field for the phone number, which is populated
      // when creating calendar events via the createCalendarLink function
      // This is more efficient than checking both description and location
      return event.location && 
        event.location.replace(/\D/g, '').includes(phone);
    });
  },

  // Clear cache for testing or when refreshing data intentionally
  clearCalendarCache: () => {
    googleContactsService._cache.calendarEvents = {};
    console.log('Calendar events cache cleared');
  },

  /**
   * Get the OAuth setup URL for initial authorization
   * @returns {Promise<Object>} - Response with authorization URL
   */
  getAuthUrl: async () => {
    try {
      // Use the optimized axios instance
      const response = await apiClient.get(
        `/public/google-contacts/setup`
      );
      
      return response.data;
    } catch (error) {
      console.error('Error getting auth URL:', error);
      throw new Error(error.response?.data?.error || 'Failed to get authorization URL');
    }
  },
  
  /**
   * Get a reauthorization URL to get a new refresh token with updated scopes
   * This is needed when new scopes (like calendar access) are added
   * @returns {Promise<Object>} - Response with reauthorization URL
   */
  getReauthorizationUrl: async () => {
    try {
      // Add cache-busting query parameter
      const timestamp = new Date().getTime();
      
      // Use a direct fetch call to avoid any routing issues
      const response = await fetch(`${API_URL}/public/google-contacts/reauth?_=${timestamp}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      });
      
      if (!response.ok) {
        throw new Error(`Request failed with status ${response.status}: ${response.statusText}`);
      }
      
      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        console.error('Invalid content type:', contentType);
        const text = await response.text();
        console.error('Response was not JSON:', text.substring(0, 500) + '...');
        throw new Error('Invalid response format: Not JSON');
      }
      
      // Parse the JSON response
      const data = await response.json();
      console.log('Reauthorization response:', data);
      
      // Validate the response
      if (!data) {
        throw new Error('Empty response received');
      }
      
      if (!data.success) {
        throw new Error(data.error || 'Request failed');
      }
      
      if (!data.authUrl) {
        console.error('Response data:', data);
        throw new Error('Invalid response format - missing authUrl');
      }
      
      return data;
    } catch (error) {
      console.error('Error getting reauthorization URL:', error);
      throw error;
    }
  }
};

export default googleContactsService; 