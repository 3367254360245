import React, { createContext, useState, useEffect } from 'react';
import googleContactsService from '../services/googleContactsService';

export const GoogleContactsContext = createContext();

export const GoogleContactsProvider = ({ children }) => {
  const [authorized, setAuthorized] = useState(false);
  const [isLoadingCalendar, setIsLoadingCalendar] = useState(false);
  const [allCalendarEvents, setAllCalendarEvents] = useState([]);
  const [lastCalendarFetch, setLastCalendarFetch] = useState(null);
  const [calendarEvents, setCalendarEvents] = useState({});

  useEffect(() => {
    const checkAuth = async () => {
      const isAuthed = await googleContactsService.checkAuthorization();
      setAuthorized(isAuthed);
    };
    checkAuth();
  }, []);

  const createContact = async (lead) => {
    try {
      if (!authorized) {
        await googleContactsService.authenticate();
        setAuthorized(true);
      }
      
      const result = await googleContactsService.createContact(lead);
      console.log('Contact result:', result);
      
      // Show appropriate feedback in UI based on whether contact was new or already existed
      const feedbackEl = document.getElementById('feedback-message');
      if (feedbackEl) {
        if (result.existingContact) {
          feedbackEl.textContent = `Contact already exists: ${lead.first_name} ${lead.last_name}`;
          feedbackEl.className = 'fixed bottom-4 right-4 bg-yellow-100 text-yellow-800 px-4 py-2 rounded shadow-md';
        } else {
          feedbackEl.textContent = `Contact added: ${lead.first_name} ${lead.last_name}`;
          feedbackEl.className = 'fixed bottom-4 right-4 bg-green-100 text-green-800 px-4 py-2 rounded shadow-md';
        }
        setTimeout(() => {
          feedbackEl.textContent = '';
          feedbackEl.className = 'hidden';
        }, 3000);
      }
      
      return result;
    } catch (error) {
      console.error('Failed to create Google contact:', error);
      
      // Show error in UI
      const feedbackEl = document.getElementById('feedback-message');
      if (feedbackEl) {
        feedbackEl.textContent = `Failed to add contact: ${error.message}`;
        feedbackEl.className = 'fixed bottom-4 right-4 bg-red-100 text-red-800 px-4 py-2 rounded shadow-md';
        setTimeout(() => {
          feedbackEl.textContent = '';
          feedbackEl.className = 'hidden';
        }, 3000);
      }
      
      throw error;
    }
  };

  const scheduleEvent = async (lead) => {
    try {
      if (!authorized) {
        await googleContactsService.authenticate();
        setAuthorized(true);
      }
      
      const result = await googleContactsService.createCalendarEvent(lead);
      await fetchCalendarEvents(true); // Refresh events after scheduling
      
      // Show feedback in UI
      const feedbackEl = document.getElementById('feedback-message');
      if (feedbackEl) {
        feedbackEl.textContent = `Event scheduled with ${lead.first_name} ${lead.last_name}`;
        feedbackEl.className = 'fixed bottom-4 right-4 bg-green-100 text-green-800 px-4 py-2 rounded shadow-md';
        setTimeout(() => {
          feedbackEl.textContent = '';
          feedbackEl.className = 'hidden';
        }, 3000);
      }
      
      return result;
    } catch (error) {
      console.error('Failed to schedule event:', error);
      
      // Show error in UI
      const feedbackEl = document.getElementById('feedback-message');
      if (feedbackEl) {
        feedbackEl.textContent = `Failed to schedule event: ${error.message}`;
        feedbackEl.className = 'fixed bottom-4 right-4 bg-red-100 text-red-800 px-4 py-2 rounded shadow-md';
        setTimeout(() => {
          feedbackEl.textContent = '';
          feedbackEl.className = 'hidden';
        }, 3000);
      }
      
      throw error;
    }
  };

  const fetchCalendarEvents = async (forceRefresh = false) => {
    if (isLoadingCalendar && !forceRefresh) return;
    
    // Check if we already fetched recently (within 2 minutes) and not forcing refresh
    const now = new Date().getTime();
    if (!forceRefresh && lastCalendarFetch && (now - lastCalendarFetch < 2 * 60 * 1000)) {
      console.log('Skipping calendar fetch - last fetch was recent');
      return;
    }
    
    setIsLoadingCalendar(true);
    try {
      console.log('Fetching all calendar events');
      const response = await googleContactsService.fetchAllCalendarEvents(7, forceRefresh); // Get events for next 7 days
      
      if (response.success && response.events) {
        setAllCalendarEvents(response.events);
        setLastCalendarFetch(now);
        
        // Add visual confirmation for user if this was a manual refresh
        if (forceRefresh) {
          const feedbackEl = document.getElementById('feedback-message');
          if (feedbackEl) {
            feedbackEl.textContent = `Calendar refreshed: ${response.events.length} events found`;
            feedbackEl.className = 'fixed bottom-4 right-4 bg-green-100 text-green-800 px-4 py-2 rounded shadow-md';
            setTimeout(() => {
              feedbackEl.textContent = '';
              feedbackEl.className = 'hidden';
            }, 3000);
          }
        }
      } else {
        console.error('Failed to fetch calendar events:', response.error);
        
        // Show error feedback if this was a manual refresh
        if (forceRefresh) {
          const feedbackEl = document.getElementById('feedback-message');
          if (feedbackEl) {
            feedbackEl.textContent = `Failed to refresh calendar: ${response.error || 'Unknown error'}`;
            feedbackEl.className = 'fixed bottom-4 right-4 bg-red-100 text-red-800 px-4 py-2 rounded shadow-md';
            setTimeout(() => {
              feedbackEl.textContent = '';
              feedbackEl.className = 'hidden';
            }, 3000);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching calendar events:', error);
      
      // Show error feedback if this was a manual refresh
      if (forceRefresh) {
        const feedbackEl = document.getElementById('feedback-message');
        if (feedbackEl) {
          feedbackEl.textContent = `Error refreshing calendar: ${error.message}`;
          feedbackEl.className = 'fixed bottom-4 right-4 bg-red-100 text-red-800 px-4 py-2 rounded shadow-md';
          setTimeout(() => {
            feedbackEl.textContent = '';
            feedbackEl.className = 'hidden';
          }, 3000);
        }
      }
    } finally {
      setIsLoadingCalendar(false);
    }
  };

  const refreshCalendarEvents = () => {
    return fetchCalendarEvents(true); // Force refresh
  };

  const clearCalendarCache = () => {
    // This will force a complete refresh by clearing local state
    setAllCalendarEvents([]);
    setCalendarEvents({});
    setLastCalendarFetch(null);
    googleContactsService.clearCalendarCache();
  };

  // Fetch calendar events periodically
  useEffect(() => {
    if (authorized) {
      fetchCalendarEvents();
      
      const intervalId = setInterval(() => {
        fetchCalendarEvents();
      }, 5 * 60 * 1000); // 5 minutes
      
      return () => clearInterval(intervalId);
    }
  }, [authorized]);

  return (
    <GoogleContactsContext.Provider value={{
      authorized,
      isLoadingCalendar,
      allCalendarEvents,
      calendarEvents,
      createContact,
      scheduleEvent,
      refreshCalendarEvents,
      clearCalendarCache
    }}>
      {children}
    </GoogleContactsContext.Provider>
  );
}; 