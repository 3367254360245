import React, { useContext, useState } from "react";
import { formatDate, formatDateTime, formatPhoneNumber, getPriorityClasses, formatEventDate, getPriorityLabel, createCalendarLink } from "../utils/formatters";
import { GoogleContactsContext } from "../contexts/GoogleContactsContext";

// Custom scrollbar styles
const scrollbarStyles = `
  .custom-scrollbar::-webkit-scrollbar {
    width: 6px;
  }
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 4px;
  }
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #ccc;
  }
`;

const LeadsTable = ({ 
  leads, 
  sortConfig, 
  onSort, 
  filters,
  commonTaskNames,
  // Lead management functions passed from parent
  updateStatus, 
  updateLeadPriority,
  getHighestPriority,
  toggleTaskComplete,
  updateDetails,
  handleDetailsChange,
  editedDetails,
  setEditedDetails,
  editingTasks,
  setEditingTasks,
  addTask,
  saveTaskChanges,
  updateProviders,
  editingProviders,
  setEditingProviders
}) => {
  // Get context but provide fallback default values if context is undefined
  const contextValue = useContext(GoogleContactsContext);
  const { 
    createContact = () => console.log('Contact creation not available'), 
    scheduleEvent = () => console.log('Event scheduling not available'), 
    isLoadingCalendar = false, 
    allCalendarEvents = [],
    refreshCalendarEvents = () => console.log('Calendar refresh not available')
  } = contextValue || {};

  const [addingContactId, setAddingContactId] = useState(null);

  if (!leads || !Array.isArray(leads) || !leads.length) {
    return <div className="text-center py-8 text-gray-500">No leads found matching your filters.</div>;
  }

  // Helper function to copy to clipboard
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => console.log('Phone number copied'),
      (err) => console.error('Failed to copy text: ', err)
    );
  };

  // Get calendar events for a lead
  const getLeadEvents = (lead) => {
    if (!allCalendarEvents?.length) return [];
    
    // Only extract phone for matching, as requested by user
    // Clean the phone number to only contain digits for reliable matching
    const phone = lead.phone ? lead.phone.replace(/\D/g, '') : null;
    
    // No phone number means no way to match reliably
    if (!phone) {
      return [];
    }
    
    // Filter events that match this lead - ONLY match by phone number in location
    return allCalendarEvents.filter(event => {
      // Only check the location field for the phone number, which is populated
      // when creating calendar events via the createCalendarLink function
      return event.location && 
        event.location.replace(/\D/g, '').includes(phone);
    });
  };

  // Render phone column with actions
  const renderPhoneColumn = (lead) => {
    const formattedPhone = formatPhoneNumber(lead.phone);
    const events = getLeadEvents(lead);

    return (
      <div className="space-y-4">
        {/* Contact Info Section */}
        <div className="space-y-2 border-b border-gray-100 pb-3">
          <div>
            <div className="font-medium text-sm text-gray-700">Phone:</div>
            <div className="flex items-center">
              <span className="text-sm font-mono truncate mr-1">{lead.phone || 'N/A'}</span>
              {lead.phone && (
                <div className="flex space-x-1">
                  <button
                    onClick={() => copyToClipboard(lead.phone)}
                    className="text-gray-500 hover:text-gray-700"
                    title="Copy phone number"
                  >
                    <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
                      <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
                    </svg>
                  </button>
                </div>
              )}
            </div>
          </div>
          <div>
            <div className="font-medium text-sm text-gray-700">Email:</div>
            <div className="text-sm break-all truncate">{lead.email || 'N/A'}</div>
          </div>
        </div>

        {/* Calendar Events Section */}
        <div className="space-y-2 border-b border-gray-100 pb-3">
          <div className="flex justify-between items-center">
            <div className="font-medium text-sm text-gray-700">Calendar:</div>
            <a
              href={createCalendarLink(lead)}
              target="_blank"
              rel="noopener noreferrer"
              className="text-indigo-600 hover:text-indigo-800 text-xs flex items-center bg-indigo-50 px-2 py-1 rounded"
              title="Schedule in Google Calendar"
            >
              <svg className="w-3 h-3 mr-1" fill="currentColor" viewBox="0 0 20 20">
                <path d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" fillRule="evenodd"></path>
              </svg>
              <span>Schedule</span>
            </a>
          </div>
          <div>
            {isLoadingCalendar ? (
              <div className="text-xs text-gray-500 flex items-center p-2 bg-gray-50 rounded">
                <svg className="animate-spin -ml-1 mr-1 h-3 w-3 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Loading...
              </div>
            ) : events.length > 0 ? (
              <div className="text-xs bg-green-50 border border-green-200 rounded-md p-2 space-y-1">
                <div className="font-medium text-green-800 flex items-center text-xs">
                  <svg className="w-3 h-3 mr-1" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path>
                  </svg>
                  Meetings: {events.length}
                </div>
                {events.slice(0, 1).map((event, idx) => (
                  <div key={idx} className="pl-3 flex flex-col">
                    <a 
                      href={event.htmlLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-medium text-blue-600 hover:text-blue-800 truncate text-xs"
                    >
                      {event.summary}
                    </a>
                    <div className="text-gray-600 text-xs">
                      {formatEventDate(event.start)}
                    </div>
                  </div>
                ))}
                <div className="text-center mt-1">
                  <button 
                    onClick={() => refreshCalendarEvents()}
                    className="text-xs text-blue-600 hover:text-blue-800 flex items-center justify-center w-full"
                    disabled={isLoadingCalendar}
                  >
                    <svg className={`w-3 h-3 mr-1 ${isLoadingCalendar ? 'animate-spin' : ''}`} fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd"></path>
                    </svg>
                    Refresh
                  </button>
                </div>
              </div>
            ) : (
              <div className="text-xs text-gray-500 flex justify-between items-center p-2 bg-gray-50 rounded">
                <span>No meetings</span>
                <button 
                  onClick={() => refreshCalendarEvents()}
                  className="text-xs text-blue-600 hover:text-blue-800"
                  disabled={isLoadingCalendar}
                >
                  Refresh
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Action Buttons Section */}
        <div className="grid grid-cols-3 gap-2 pt-1">
          {/* Call with Google Voice */}
          <a
            href={`https://voice.google.com/u/0/calls?a=nc,${formattedPhone.callFormat}`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col items-center justify-center text-green-600 hover:text-green-800 p-2 hover:bg-green-50 transition-colors rounded border border-gray-100"
            title="Call with Google Voice"
          >
            <svg className="w-4 h-4 mb-1" fill="currentColor" viewBox="0 0 20 20">
              <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
            </svg>
            <span className="text-xs">Call</span>
          </a>
          
          {/* Send message sequence via extension */}
          <button
            onClick={async () => {
              console.log('Send Message button clicked');
              
              // Check if extension is installed
              if (!window.chrome?.runtime?.sendMessage) {
                alert('Error: QuickChron extension not found. Please install the extension to use this feature.');
                return;
              }

              // Add confirmation dialog before proceeding
              const confirmed = window.confirm(
                `Send message sequence to ${lead.first_name || ''} ${lead.last_name || ''}?`
              );
              
              if (!confirmed) return;

              try {
                // Get the first name from the lead
                const firstName = lead.first_name || '';
                
                // Prepare message sequence with personalized first message
                const messageSequence = [
                  `Hi ${firstName}! This is CheckMyCharts reaching out about your request for us to look into a case of medical negligence.`,
                  `I read your request and I'm terribly sorry you're going through this!`,
                  `I'll call back tomorrow but is there a convenient time?`
                ];
                
                // Store data in extension
                await new Promise((resolve, reject) => {
                  chrome.runtime.sendMessage('bhdaafpblckkoaoilnbcheigjengmcee', {
                    action: 'storeData',
                    data: {
                      lastPhoneNumber: lead.phone,
                      defaultMessage: messageSequence
                    }
                  }, response => {
                    if (chrome.runtime.lastError) {
                      reject(chrome.runtime.lastError);
                      return;
                    }
                    resolve(response);
                  });
                });

                // After successful storage, trigger the extension action
                chrome.runtime.sendMessage('bhdaafpblckkoaoilnbcheigjengmcee', {
                  action: 'openAndSend'
                });
              } catch (error) {
                console.error('Error with extension:', error);
                alert('Error: Could not communicate with the QuickChron extension.');
              }
            }}
            className="flex flex-col items-center justify-center text-blue-600 hover:text-blue-800 p-2 hover:bg-blue-50 transition-colors rounded border border-gray-100"
            title="Send message sequence via Chrome extension"
          >
            <svg className="w-4 h-4 mb-1" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clipRule="evenodd" />
            </svg>
            <span className="text-xs">Text</span>
          </button>
          
          {/* Add to Google Contacts */}
          <button
            onClick={() => {
              setAddingContactId(lead.id);
              createContact(lead)
                .then((result) => {
                  // Feedback is handled in the GoogleContactsContext
                  // But we can add additional UI feedback here if needed
                  if (result && result.existingContact) {
                    console.log('Contact already exists:', result);
                  } else {
                    console.log('Contact created:', result);
                  }
                })
                .catch(error => {
                  console.error('Error adding contact:', error);
                  // Error feedback is handled in the GoogleContactsContext
                })
                .finally(() => {
                  setAddingContactId(null);
                });
            }}
            className="flex flex-col items-center justify-center text-purple-600 hover:text-purple-800 p-2 hover:bg-purple-50 transition-colors rounded border border-gray-100"
            title="Add to Google Contacts"
            disabled={addingContactId === lead.id}
          >
            {addingContactId === lead.id ? (
              <svg className="w-4 h-4 mb-1 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            ) : (
              <svg className="w-4 h-4 mb-1" fill="currentColor" viewBox="0 0 24 24">
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z" />
              </svg>
            )}
            <span className="text-xs">Contact</span>
          </button>
        </div>
      </div>
    );
  };

  // Render tasks column with task management
  const renderTasksColumn = (lead) => {
    const tasks = lead.tasks || [];
    const sortedTasks = [...tasks].sort((a, b) => b.priority - a.priority);

    return (
      <div className="flex flex-col space-y-1 max-h-[200px] overflow-y-auto">
        {/* Task List */}
        {sortedTasks.map((task, index) => (
          <div key={index} className="flex items-center space-x-1 group text-sm">
            <input
              type="checkbox"
              checked={task.complete}
              onChange={() => toggleTaskComplete(lead.id, index)}
              className="h-3 w-3 text-blue-600"
            />
            {editingTasks?.leadId === lead.id ? (
              <>
                <select
                  data-lead-id={lead.id}
                  value={task.name}
                  onChange={(e) => {
                    const newTasks = [...editingTasks.tasks];
                    newTasks[index] = { ...newTasks[index], name: e.target.value };
                    setEditingTasks({ ...editingTasks, tasks: newTasks });
                  }}
                  className="border rounded px-1 py-0.5 text-xs flex-1"
                >
                  <option value="">Select Task</option>
                  {commonTaskNames && commonTaskNames.map((name, idx) => (
                    <option key={idx} value={name}>{name}</option>
                  ))}
                  <option value="custom">Custom Task...</option>
                </select>
                {task.name === 'custom' && (
                  <input
                    type="text"
                    value={task.customName || ''}
                    onChange={(e) => {
                      const newTasks = [...editingTasks.tasks];
                      newTasks[index] = { ...newTasks[index], customName: e.target.value };
                      setEditingTasks({ ...editingTasks, tasks: newTasks });
                    }}
                    className="border rounded px-1 py-0.5 text-xs"
                    placeholder="Custom task..."
                  />
                )}
                <select
                  value={task.priority}
                  onChange={(e) => {
                    const newTasks = [...editingTasks.tasks];
                    newTasks[index] = { ...newTasks[index], priority: parseInt(e.target.value) };
                    setEditingTasks({ ...editingTasks, tasks: newTasks });
                  }}
                  className="border rounded px-1 py-0.5 text-xs"
                >
                  <option value="4">Critical</option>
                  <option value="3">High</option>
                  <option value="2">Medium</option>
                  <option value="1">Low</option>
                  <option value="0">None</option>
                </select>
                <button
                  onClick={() => {
                    const newTasks = editingTasks.tasks.filter((_, i) => i !== index);
                    setEditingTasks({ ...editingTasks, tasks: newTasks });
                  }}
                  className="text-red-600 hover:text-red-800 p-0.5"
                  title="Delete task"
                >
                  <svg className="w-3 h-3" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
              </>
            ) : (
              <>
                <span className={`text-xs flex-1 truncate ${task.complete ? 'line-through text-gray-500' : ''}`}>
                  {task.name === 'custom' ? task.customName : task.name}
                </span>
                <span className={`text-xs ml-1 px-1 py-0.5 rounded ${getPriorityClasses(task.priority)}`}>
                  {getPriorityLabel(task.priority)}
                </span>
              </>
            )}
          </div>
        ))}

        {/* Task Management Buttons */}
        {editingTasks?.leadId === lead.id ? (
          <div className="flex justify-end space-x-1 mt-1">
            <button
              onClick={() => {
                const newTasks = [...editingTasks.tasks, { name: '', complete: false, priority: 1 }];
                setEditingTasks({ ...editingTasks, tasks: newTasks });
              }}
              className="text-blue-600 hover:text-blue-800 text-xs"
            >
              + Add
            </button>
            <button
              onClick={() => saveTaskChanges(lead.id)}
              className="bg-blue-500 hover:bg-blue-600 text-white px-1 py-0.5 rounded text-xs"
            >
              Save
            </button>
            <button
              onClick={() => setEditingTasks(null)}
              className="bg-gray-300 hover:bg-gray-400 text-gray-700 px-1 py-0.5 rounded text-xs"
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className="flex justify-between items-center">
            <button
              onClick={() => addTask(lead.id)}
              className="text-blue-600 hover:text-blue-800 text-xs flex items-center bg-blue-50 hover:bg-blue-100 px-2 py-1 rounded-md transition-colors"
            >
              <svg className="w-3 h-3 mr-1" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
              </svg>
              Add Task
            </button>
            <div className="text-xs text-gray-500">
              {sortedTasks.length > 0 ? 
                `${sortedTasks.filter(t => t.complete).length}/${sortedTasks.length} done` : 
                "No tasks"
              }
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <style>{scrollbarStyles}</style>
      <table className="w-full table-auto border-collapse bg-white rounded-lg">
        <colgroup>
          <col style={{ width: '8%' }} /> {/* Name */}
          <col style={{ width: '10%' }} /> {/* Condition */}
          <col style={{ width: '7%' }} /> {/* Date */}
          <col style={{ width: '8%' }} /> {/* Created */}
          <col style={{ width: '5%' }} /> {/* State */}
          <col style={{ width: '7%' }} /> {/* Status */}
          <col style={{ width: '7%' }} /> {/* Priority */}
          <col style={{ width: '15%' }} /> {/* Details */}
          <col style={{ width: '13%' }} /> {/* Contact */}
          <col style={{ width: '10%' }} /> {/* Providers */}
          <col style={{ width: '10%' }} /> {/* Tasks */}
        </colgroup>
        <thead className="bg-gray-100">
          <tr>
            <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
            <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Condition</th>
            <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
            <th 
              onClick={() => onSort('created_at')} 
              className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-200"
            >
              Created {sortConfig.field === 'created_at' && (sortConfig.direction === 'desc' ? '↓' : '↑')}
            </th>
            <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">State</th>
            <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
            <th 
              onClick={() => onSort('priority')} 
              className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-200"
            >
              Priority {sortConfig.field === 'priority' && (sortConfig.direction === 'desc' ? '↓' : '↑')}
            </th>
            <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Details</th>
            <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Contact</th>
            <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Providers</th>
            <th 
              onClick={() => onSort('tasks')} 
              className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-200"
            >
              Tasks {sortConfig.field === 'tasks' && (sortConfig.direction === 'desc' ? '↓' : '↑')}
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {leads.map((lead) => (
            <tr key={lead.id} className="hover:bg-gray-50">
              <td className="px-2 py-2 whitespace-nowrap text-sm">
                <div className="truncate max-w-full">
                  {`${lead.first_name || ''} ${lead.last_name || ''}`}
                </div>
              </td>
              <td className="px-2 py-2 text-sm">
                <div className="h-[120px] overflow-y-auto pr-1 custom-scrollbar">
                  <p className="break-words whitespace-pre-line">{lead.condition || 'N/A'}</p>
                </div>
              </td>
              <td className="px-2 py-2 whitespace-nowrap text-xs font-mono">
                <span className="truncate">{formatDate(lead.incident_date)}</span>
              </td>
              <td className="px-2 py-2 whitespace-nowrap text-xs font-mono">
                <span className="truncate">{formatDateTime(lead.created_at)}</span>
              </td>
              <td className="px-2 py-2 whitespace-nowrap text-sm">
                <span className="truncate">{lead.state || 'N/A'}</span>
              </td>
              <td className="px-2 py-2 whitespace-nowrap">
                <select
                  value={lead.status || ''}
                  onChange={(e) => updateStatus(lead.id, e.target.value)}
                  className="border rounded px-2 py-1 text-xs w-full"
                >
                  <option value="">Select</option>
                  <option value="new">New</option>
                  <option value="pending">Pending</option>
                  <option value="contacted">Contacted</option>
                  <option value="confirmed">Confirmed</option>
                  <option value="qualified">Qualified</option>
                  <option value="disqualified">Disqualified</option>
                  <option value="converted">Converted</option>
                </select>
              </td>
              <td className="px-2 py-2 whitespace-nowrap">
                <select
                  value={getHighestPriority(lead.tasks)}
                  onChange={(e) => updateLeadPriority(lead.id, parseInt(e.target.value))}
                  className={`border rounded px-2 py-1 text-xs font-medium w-full
                    ${getHighestPriority(lead.tasks) === 4 ? 'bg-purple-100 text-purple-800 border-purple-200' : 
                      getHighestPriority(lead.tasks) === 3 ? 'bg-red-100 text-red-800 border-red-200' : 
                      getHighestPriority(lead.tasks) === 2 ? 'bg-yellow-100 text-yellow-800 border-yellow-200' : 
                      getHighestPriority(lead.tasks) === 1 ? 'bg-green-100 text-green-800 border-green-200' : 
                      'bg-gray-100 text-gray-800 border-gray-200'}`}
                >
                  <option value="4">Critical</option>
                  <option value="3">High</option>
                  <option value="2">Medium</option>
                  <option value="1">Low</option>
                  <option value="0">None</option>
                </select>
              </td>
              <td className="px-2 py-2">
                <div className="flex flex-col space-y-2">
                  <div className="max-h-[120px] overflow-y-auto">
                    <textarea
                      value={editedDetails[lead.id] !== undefined ? editedDetails[lead.id] : lead.details || ''}
                      onChange={(e) => handleDetailsChange(lead.id, e.target.value)}
                      className="w-full border rounded px-2 py-1 min-h-[80px] text-sm"
                      placeholder="Enter details..."
                      rows={4}
                    />
                  </div>
                  {editedDetails[lead.id] !== undefined && (
                    <div className="flex justify-end space-x-2">
                      <button
                        onClick={() => updateDetails(lead.id)}
                        className="bg-blue-500 hover:bg-blue-600 text-white px-2 py-1 rounded text-xs"
                      >
                        Save
                      </button>
                      <button
                        onClick={() => {
                          setEditedDetails(prev => {
                            const newState = { ...prev };
                            delete newState[lead.id];
                            return newState;
                          });
                        }}
                        className="bg-gray-300 hover:bg-gray-400 text-gray-700 px-2 py-1 rounded text-xs"
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              </td>
              <td className="px-2 py-2">
                {renderPhoneColumn(lead)}
              </td>
              <td className="px-2 py-2">
                {editingProviders === lead.id ? (
                  <div className="flex flex-col space-y-1">
                    {(lead.providers || []).map((provider, index) => (
                      <div key={index} className="flex items-center space-x-1">
                        <input
                          type="text"
                          value={provider}
                          onChange={(e) => {
                            const newProviders = [...(lead.providers || [])];
                            newProviders[index] = e.target.value;
                            updateProviders(lead.id, newProviders);
                          }}
                          className="border rounded px-1 py-0.5 text-xs flex-1"
                        />
                        <button
                          onClick={() => {
                            const newProviders = (lead.providers || []).filter((_, i) => i !== index);
                            updateProviders(lead.id, newProviders);
                          }}
                          className="text-red-600 hover:text-red-800"
                          title="Remove provider"
                        >
                          <svg className="w-3 h-3" fill="currentColor" viewBox="0 0 20 20">
                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                          </svg>
                        </button>
                      </div>
                    ))}
                    <div className="flex space-x-1">
                      <button
                        onClick={() => {
                          const newProviders = [...(lead.providers || []), ''];
                          updateProviders(lead.id, newProviders);
                        }}
                        className="text-blue-600 hover:text-blue-800 text-xs"
                      >
                        + Add
                      </button>
                      <button
                        onClick={() => setEditingProviders(null)}
                        className="bg-gray-300 hover:bg-gray-400 text-gray-700 px-1 py-0.5 rounded text-xs"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center space-x-1">
                    <div className="flex-1 max-h-[120px] overflow-y-auto">
                      {lead.providers?.length ? (
                        <div className="space-y-0.5">
                          {lead.providers.map((provider, index) => (
                            <div key={index} className="text-xs truncate">
                              {provider}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <span className="text-gray-500 text-xs">No providers</span>
                      )}
                    </div>
                    <button
                      onClick={() => setEditingProviders(lead.id)}
                      className="text-blue-600 hover:text-blue-800"
                      title="Edit providers"
                    >
                      <svg className="w-3 h-3" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                      </svg>
                    </button>
                  </div>
                )}
              </td>
              <td className="px-2 py-2">
                {renderTasksColumn(lead)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default LeadsTable; 