import React, { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import googleContactsService from "../services/googleContactsService";

const AuthAdmin = () => {
  const [session, setSession] = useState(null);
  const [authorized, setAuthorized] = useState(false);
  const [authUrl, setAuthUrl] = useState("");
  const [reAuthUrl, setReAuthUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const checkAuthorization = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setSession(session);
      const authorizedEmails = ["ptander@gmail.com", "afterimagejustice@gmail.com"];
      setAuthorized(authorizedEmails.includes(session?.user?.email));
    };

    checkAuthorization();
  }, []);

  const getAuthUrl = async () => {
    try {
      setLoading(true);
      setMessage("");
      const response = await googleContactsService.getAuthUrl();
      setAuthUrl(response.authUrl);
      setMessage("Authorization URL generated successfully.");
    } catch (error) {
      console.error('Error getting auth URL:', error);
      setMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const getReAuthUrl = async () => {
    try {
      setLoading(true);
      setMessage("");
      const response = await googleContactsService.getReauthorizationUrl();
      setReAuthUrl(response.authUrl);
      setMessage(response.message || "Reauthorization URL generated successfully.");
    } catch (error) {
      console.error('Error getting reauth URL:', error);
      setMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  if (!authorized) {
    return <div className="container mx-auto px-4 py-8">Not authorized to view this page.</div>;
  }

  return (
    <div className="w-full max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">Google OAuth Administration</h1>
      
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-4">Initial Authorization</h2>
        <p className="text-gray-600 mb-4">
          Generate an initial authorization URL to connect to Google APIs.
        </p>
        <button
          onClick={getAuthUrl}
          disabled={loading}
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded disabled:opacity-50"
        >
          {loading ? "Loading..." : "Generate Auth URL"}
        </button>
        
        {authUrl && (
          <div className="mt-4">
            <h3 className="font-medium mb-2">Authorization URL:</h3>
            <div className="bg-gray-100 p-3 rounded-md overflow-x-auto">
              <a 
                href={authUrl} 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 break-all"
              >
                {authUrl}
              </a>
            </div>
            <p className="text-sm text-gray-500 mt-2">
              Click the link above to start the authorization process.
            </p>
          </div>
        )}
      </div>
      
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-4">Reauthorization (For New Scopes)</h2>
        <p className="text-gray-600 mb-4">
          Generate a reauthorization URL to add new API scopes (like Calendar access).
        </p>
        <button
          onClick={getReAuthUrl}
          disabled={loading}
          className="bg-purple-500 hover:bg-purple-600 text-white px-4 py-2 rounded disabled:opacity-50"
        >
          {loading ? "Loading..." : "Generate Reauth URL"}
        </button>
        
        {reAuthUrl && (
          <div className="mt-4">
            <h3 className="font-medium mb-2">Reauthorization URL:</h3>
            <div className="bg-gray-100 p-3 rounded-md overflow-x-auto">
              <a 
                href={reAuthUrl} 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 break-all"
              >
                {reAuthUrl}
              </a>
            </div>
            <p className="text-sm text-gray-500 mt-2">
              Click the link above to start the reauthorization process with calendar scope.
              After authorization, you'll receive a new refresh token. Update this token in your environment variables.
            </p>
          </div>
        )}
      </div>
      
      {message && (
        <div className={`p-4 rounded-md ${message.startsWith('Error') ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'}`}>
          {message}
        </div>
      )}
      
      <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-6 mt-6">
        <h2 className="text-xl font-semibold mb-4">Instructions</h2>
        <ol className="list-decimal pl-6 space-y-2">
          <li>Click "Generate Reauth URL" to create a new authorization link with Calendar scope</li>
          <li>Open the generated URL and authorize your Google account with the additional scopes</li>
          <li>After authorization, you'll be redirected to a page with the new tokens</li>
          <li>Copy the refresh_token value from the response</li>
          <li>Update your environment variable GOOGLE_REFRESH_TOKEN with this new value</li>
          <li>Restart your application for the changes to take effect</li>
        </ol>
      </div>
    </div>
  );
};

export default AuthAdmin; 