import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Download, ChevronDown, ChevronUp, Search, AlertCircle, Info, Calendar } from 'lucide-react';
import { downloadFile, fetchAnalysis, fetchPdfUrl } from "../../services/api";
import CollapsiblePDFDisplay from "../../components/CollapsiblePDFDisplay";

// Helper function to format currency
const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(amount);
};

// Helper function to format dates
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

const DiagnosisCodeTooltip = ({ 
  code, 
  description, 
  isDisputed = false, 
  codeDescriptions, 
  isCausallyRelated = false,
  reason = null 
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const containerRef = useRef(null);
  
  // Use the description passed directly or look it up from the centralized mapping
  let codeDescription = description;
  if (!codeDescription && codeDescriptions) {
    codeDescription = typeof codeDescriptions === 'object' && codeDescriptions[code] 
      ? codeDescriptions[code] 
      : 'No description available';
  }
  
  // Determine color based on causal relationship
  const getBadgeColor = () => {
    if (isCausallyRelated) return 'bg-green-100 text-green-800';
    return 'bg-red-100 text-red-800';
  };
  
  const handleMouseEnter = () => {
    setShowTooltip(true);
  };
  
  const handleMouseLeave = () => {
    setShowTooltip(false);
  };
  
  return (
    <div 
      className="relative inline-block" 
      ref={containerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span 
        className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
          getBadgeColor()
        } cursor-help`}
      >
        {code}
        {isDisputed && <AlertCircle className="h-3 w-3 ml-1" />}
      </span>
      
      {showTooltip && (
        <div className="fixed z-[9999] w-72 px-3 py-2 text-sm font-normal text-gray-900 bg-white border border-gray-200 rounded-lg shadow-lg"
          style={{ 
            top: `${containerRef.current.getBoundingClientRect().top - 10}px`,
            left: `${containerRef.current.getBoundingClientRect().right + 10}px`,
            boxShadow: '0 10px 25px rgba(0, 0, 0, 0.1)',
            pointerEvents: 'none' // This is important to prevent the tooltip from blocking hover
          }}>
          <p className="font-medium mb-1">{code}</p>
          <p className="mb-2">{codeDescription}</p>
          {reason && (
            <p className="text-xs text-gray-600 border-t pt-2">{reason}</p>
          )}
          {!isCausallyRelated && (
            <p className="text-red-600 text-xs border-t pt-2">This code is not causally related to the incident</p>
          )}
          {isCausallyRelated && (
            <p className="text-green-600 text-xs border-t pt-2">This code is causally related to the incident</p>
          )}
        </div>
      )}
    </div>
  );
};

const CaseMetadataCard = ({ metadata, codeDescriptions }) => {
  if (!metadata) return null;
  
  const { name, case_id, case_type, medicare_id, date_of_incident, reported_diagnosis_codes } = metadata;
  
  return (
    <div className="bg-white border rounded-lg shadow mb-6 overflow-hidden">
      <div className="px-6 py-4 border-b">
        <h3 className="text-lg font-semibold text-gray-900">Case Information</h3>
      </div>
      <div className="p-6 space-y-4">
        {/* Patient Info Section */}
        <div>
          <p className="text-sm text-gray-600 mb-2">Patient</p>
          <p className="font-medium">{name || 'Not available'}</p>
        </div>
        
        {/* Other case metadata */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <p className="text-sm text-gray-600 mb-2">Case ID</p>
            <p className="font-medium">{case_id || 'Not available'}</p>
          </div>
          <div>
            <p className="text-sm text-gray-600 mb-2">Case Type</p>
            <p className="font-medium">{case_type || 'Not available'}</p>
          </div>
          <div>
            <p className="text-sm text-gray-600 mb-2">Medicare ID</p>
            <p className="font-medium">{medicare_id || 'Not available'}</p>
          </div>
        </div>
        
        <div>
          <p className="text-sm text-gray-600 mb-2">Date of Incident</p>
          <p className="font-medium">{date_of_incident || 'Not available'}</p>
        </div>
        
        {/* Reported Diagnosis Codes */}
        {reported_diagnosis_codes && reported_diagnosis_codes.length > 0 && (
          <div className="mt-4">
            <p className="text-sm text-gray-600 mb-2">Reported Diagnosis Codes</p>
            <div className="flex flex-wrap gap-2">
              {reported_diagnosis_codes.map((code, index) => (
                <IncidentCodeBadge 
                  key={index} 
                  code={code} 
                  codeDescriptions={codeDescriptions}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// New component specifically for incident codes
const IncidentCodeBadge = ({ code, codeDescriptions }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const containerRef = useRef(null);
  
  // Get description from centralized mapping
  const codeDescription = codeDescriptions && codeDescriptions[code] 
    ? codeDescriptions[code] 
    : 'No description available';
  
  return (
    <div 
      className="relative inline-block"
      ref={containerRef}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <span 
        className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 cursor-help"
      >
        {code}
      </span>
      
      {showTooltip && (
        <div className="fixed z-[9999] w-64 px-3 py-2 text-sm font-normal text-gray-900 bg-white border border-gray-200 rounded-lg shadow-lg" 
          style={{ 
            top: `${containerRef.current.getBoundingClientRect().top - 10}px`,
            left: `${containerRef.current.getBoundingClientRect().right + 10}px`,
            boxShadow: '0 10px 25px rgba(0, 0, 0, 0.1)',
            pointerEvents: 'none' // This is important to prevent the tooltip from blocking hover
          }}>
          <p className="font-medium mb-1">{code}</p>
          <p className="mb-2">{codeDescription}</p>
          <p className="text-indigo-600 text-xs border-t pt-2">This is an incident code</p>
        </div>
      )}
    </div>
  );
};

const CaseStatsSummary = ({ stats }) => {
  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 mb-8">
      <h2 className="text-xl font-semibold text-gray-900 mb-4">Claims Summary</h2>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
        <div>
          <p className="text-sm text-gray-600">Total Claims</p>
          <p className="text-2xl font-semibold text-gray-900">{stats.total_claims}</p>
        </div>
        <div>
          <p className="text-sm text-gray-600">Total Amount</p>
          <p className="text-2xl font-semibold text-gray-900">{formatCurrency(stats.total_amount)}</p>
        </div>
        <div className="relative">
          <div className="flex items-center gap-2">
            <p className="text-sm text-gray-600">Claims with Unrelated Codes</p>
            <div className="group relative">
              <Info className="h-4 w-4 text-gray-400 cursor-help" />
              <div className="hidden group-hover:block absolute z-10 w-72 p-2 text-sm bg-white border rounded-lg shadow-lg -top-2 left-full ml-2">
                This shows claims containing at least one unrelated diagnosis code. The actual unrelated amount may be lower, as claims often include multiple diagnoses and treatments.
              </div>
            </div>
          </div>
          <p className="text-2xl font-semibold text-gray-900">
            {stats.claims_with_unrelated_codes} of {stats.total_claims}
            <span className="text-base font-normal text-gray-500 ml-1">
              ({Math.round((stats.claims_with_unrelated_codes / stats.total_claims) * 100)}%)
            </span>
          </p>
        </div>
        <div className="relative">
          <div className="flex items-center gap-2">
            <p className="text-sm text-gray-600">Unrelated Diagnosis Codes</p>
            <div className="group relative">
              <Info className="h-4 w-4 text-gray-400 cursor-help" />
              <div className="hidden group-hover:block absolute z-10 w-72 p-2 text-sm bg-white border rounded-lg shadow-lg -top-2 left-full ml-2">
                Percentage of all diagnosis codes across claims that are unrelated to the incident.
              </div>
            </div>
          </div>
          <p className="text-2xl font-semibold text-gray-900">
            {stats.unrelated_dx_codes} of {stats.total_dx_codes}
            <span className="text-base font-normal text-gray-500 ml-1">
              ({Math.round((stats.unrelated_dx_codes / stats.total_dx_codes) * 100)}%)
            </span>
          </p>
        </div>
        <div className="relative">
          <div className="flex items-center gap-2">
            <p className="text-sm text-gray-600">Potentially Unrelated Amount</p>
            <div className="group relative">
              <Info className="h-4 w-4 text-gray-400 cursor-help" />
              <div className="hidden group-hover:block absolute z-10 w-72 p-2 text-sm bg-white border rounded-lg shadow-lg -top-2 left-full ml-2">
                This represents claims containing unrelated codes. The actual unrelated amount may be lower and would require medical review to determine exact apportionment.
              </div>
            </div>
          </div>
          <p className="text-2xl font-semibold text-gray-900">{formatCurrency(stats.total_unrelated_amount)}</p>
        </div>
        <div>
          <p className="text-sm text-gray-600">Date Range</p>
          <p className="text-base font-medium text-gray-900">
            {formatDate(stats.date_range[0])} - {formatDate(stats.date_range[1])}
          </p>
        </div>
      </div>
    </div>
  );
};

const AppealLetterSection = ({ letter }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  // Extract the letter content from the JSON structure if needed
  const letterContent = typeof letter === 'string' ? letter : letter?.content;
  
  // Clean up the letter content
  const cleanContent = letterContent?.replace(/\\n/g, '\n')
                                   .replace(/^"/, '')
                                   .replace(/"$/, '')
                                   .trim();

  if (!cleanContent) return null;

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden mb-8">
      <div className="flex justify-between items-center p-6">
        <h2 className="text-xl font-semibold text-gray-900">Appeal Letter</h2>
        <button 
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-gray-500 hover:text-gray-700"
        >
          {isExpanded ? <ChevronUp className="h-5 w-5" /> : <ChevronDown className="h-5 w-5" />}
        </button>
      </div>
      {isExpanded && (
        <div className="px-6 pb-6">
          <div className="prose max-w-none whitespace-pre-wrap font-serif">
            {cleanContent}
          </div>
        </div>
      )}
    </div>
  );
};

const DiagnosisCodeBadge = ({ code, description, isDisputed, reason, causally_related, codeDescriptions }) => {
  return (
    <div className="relative">
      <DiagnosisCodeTooltip 
        code={code} 
        description={description} 
        isDisputed={isDisputed} 
        codeDescriptions={codeDescriptions}
        isCausallyRelated={causally_related}
        reason={reason}
      />
    </div>
  );
};

const ClaimRow = ({ 
  claim, 
  claimAnalysis, 
  unrelatedCodes, 
  codeDescriptions,
  chronologyEvents,
  pdfUrl, 
  pagesPerChunk,
  chronologyPdfUrl
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showChronology, setShowChronology] = useState(false);
  const [chronologySearchTerm, setChronologySearchTerm] = useState('');

  // Get the analysis for this claim's codes
  const codeAnalysis = claimAnalysis?.code_analysis?.codes || {};
  
  // Format provider name - strip NPI if present
  const providerName = claim.provider.split('/')[0].trim();
  
  // Filter chronology events relevant to this claim's date range
  const relevantEvents = useMemo(() => {
    if (!chronologyEvents?.length) return [];
    
    // Convert claim dates to Date objects for comparison
    const fromDate = new Date(claim.dates[0]);
    const toDate = new Date(claim.dates[1]);
    
    // Filter events within the date range
    return chronologyEvents.filter(event => {
      if (!event.date) return false;
      
      try {
        const eventDate = new Date(event.date);
        return eventDate >= fromDate && eventDate <= toDate;
      } catch (e) {
        return false;
      }
    }).sort((a, b) => {
      try {
        return new Date(a.date) - new Date(b.date);
      } catch (e) {
        return 0;
      }
    });
  }, [chronologyEvents, claim.dates]);

  // Filter events by search term
  const filteredEvents = useMemo(() => {
    if (!chronologySearchTerm.trim()) {
      return relevantEvents;
    }
    
    const term = chronologySearchTerm.toLowerCase();
    return relevantEvents.filter(event => {
      const description = (event.narrative_description || '').toLowerCase();
      const provider = (event.provider || '').toLowerCase();
      return description.includes(term) || provider.includes(term);
    });
  }, [relevantEvents, chronologySearchTerm]);

  return (
    <>
      <tr className="border-b border-gray-200 hover:bg-gray-50">
        <td className="px-6 py-4">
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-blue-600 hover:text-blue-800"
          >
            {isExpanded ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
          </button>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
          {formatDate(claim.dates[0])} - {formatDate(claim.dates[1])}
        </td>
        <td className="px-6 py-4 text-sm text-gray-900">
          {providerName}
        </td>
        <td className="px-6 py-4 text-sm text-gray-900 text-right">
          {formatCurrency(claim.amount)}
        </td>
        <td className="px-6 py-4 text-sm">
          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
            unrelatedCodes.length > 0 ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'
          }`}>
            {unrelatedCodes.length > 0 ? 'Disputed' : 'Related'}
          </span>
        </td>
      </tr>
      {isExpanded && (
        <tr className="border-b border-gray-200 bg-gray-50">
          <td colSpan="5" className="px-6 py-4">
            <div className="space-y-4">
              <div>
                <h4 className="text-sm font-medium text-gray-900 mb-2">Claim Details</h4>
                <div className="grid grid-cols-2 gap-4 text-sm">
                  <div>
                    <p className="text-gray-600">ICN/Line</p>
                    <p className="font-medium">{claim.tos_icn_line}</p>
                  </div>
                  <div>
                    <p className="text-gray-600">HCPCS/CPT/DRG</p>
                    <p className="font-medium">{claim.hcpcs_cpt_drg || 'N/A'}</p>
                  </div>
                  <div>
                    <p className="text-gray-600">Total Charges</p>
                    <p className="font-medium">{formatCurrency(claim.total_charges)}</p>
                  </div>
                  <div>
                    <p className="text-gray-600">Reimbursed Amount</p>
                    <p className="font-medium">{formatCurrency(claim.reimbursed_amount)}</p>
                  </div>
                </div>
              </div>
              
              <div>
                <h4 className="text-sm font-medium text-gray-900 mb-2">Diagnosis Codes</h4>
                <div className="flex flex-wrap gap-2">
                  {claim.codes.map(codeObj => {
                    // Extract code from code object if needed
                    const codeValue = typeof codeObj === 'string' ? codeObj : codeObj.code;
                    const analysis = codeAnalysis[codeValue] || {};
                    const isDisputed = unrelatedCodes.some(code => 
                      typeof code === 'string' ? code === codeValue : code.code === codeValue
                    );
                    return (
                      <DiagnosisCodeBadge
                        key={codeValue}
                        code={codeValue}
                        description={codeObj.description || ''}
                        isDisputed={isDisputed}
                        reason={analysis.reason}
                        causally_related={analysis.causally_related}
                        codeDescriptions={codeDescriptions}
                      />
                    );
                  })}
                </div>
              </div>
              
              {/* Enhanced Chronology Events section */}
              {chronologyEvents?.length > 0 && (
                <div>
                  <div className="flex items-center justify-between mb-2">
                    <h4 className="text-sm font-medium text-gray-900">Medical Events During Claim Period</h4>
                    <button 
                      onClick={() => setShowChronology(!showChronology)}
                      className="text-blue-600 hover:text-blue-800 flex items-center text-sm"
                    >
                      {showChronology ? (
                        <>Hide <ChevronUp className="h-4 w-4 ml-1" /></>
                      ) : (
                        <>Show {relevantEvents.length > 0 ? `(${relevantEvents.length})` : ''} <ChevronDown className="h-4 w-4 ml-1" /></>
                      )}
                    </button>
                  </div>
                  
                  {showChronology && (
                    <div className="mt-2 border rounded-md overflow-hidden">
                      {/* New search box for chronology events */}
                      {relevantEvents.length > 0 && (
                        <div className="p-3 border-b">
                          <div className="relative">
                            <input
                              type="text"
                              value={chronologySearchTerm}
                              onChange={(e) => setChronologySearchTerm(e.target.value)}
                              placeholder="Search medical events..."
                              className="w-full rounded-lg border-gray-200 shadow-sm focus:border-blue-500 focus:ring-blue-500 pl-10 py-2 text-sm"
                            />
                            <Search className="w-4 h-4 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
                          </div>
                        </div>
                      )}
                      
                      {/* Updated event display with PDF viewer */}
                      {filteredEvents.length > 0 ? (
                        <div className="divide-y divide-gray-100">
                          {filteredEvents.map((event, idx) => (
                            <div key={idx} className="p-3 hover:bg-gray-50">
                              <div className="flex justify-between text-sm mb-1">
                                <span className="flex items-center gap-1">
                                  <Calendar className="h-3.5 w-3.5 text-gray-500" />
                                  <span className="font-medium">{formatDate(event.date)}</span>
                                </span>
                                <span className="text-gray-600">{event.provider}</span>
                              </div>
                              <p className="text-sm mb-2">{event.narrative_description}</p>
                              
                              {/* Added CollapsiblePDFDisplay component */}
                              {event.chunk_id && (
                                <CollapsiblePDFDisplay
                                  chunkIds={[event.chunk_id]}
                                  pdfUrl={chronologyPdfUrl || pdfUrl}
                                  pagesPerChunk={pagesPerChunk}
                                  alertTerms={event.excerpt}
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="p-3 text-center text-gray-500 text-sm">
                          {chronologySearchTerm 
                            ? `No medical events matching "${chronologySearchTerm}"`
                            : "No medical events recorded during this claim period"}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

const ClaimsAnalysisSection = ({ 
  claims, 
  claimsAnalysis, 
  unrelatedClaims, 
  codeDescriptions,
  chronologyEvents,
  pdfUrl,
  chronologyPdfUrl,
  pagesPerChunk
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('all'); // 'all', 'disputed', 'related'

  // Create a map of unrelated codes for each claim
  const unrelatedCodesMap = {};
  unrelatedClaims.forEach(claim => {
    const key = `${claim.provider}-${claim.dates[0]}-${claim.dates[1]}`;
    unrelatedCodesMap[key] = claim.codes;
  });

  // Filter and sort claims
  const filteredClaims = claims.filter(claim => {
    const key = `${claim.provider}-${claim.dates[0]}-${claim.dates[1]}`;
    const unrelatedCodes = unrelatedCodesMap[key] || [];
    
    // Apply search filter
    const matchesSearch = searchTerm === '' || 
      claim.provider.toLowerCase().includes(searchTerm.toLowerCase()) ||
      claim.codes.some(code => {
        // Check if code is a string or an object
        const codeValue = typeof code === 'string' ? code : (code.code || '');
        return codeValue.toLowerCase().includes(searchTerm.toLowerCase());
      });

    // Apply type filter
    const matchesType = filterType === 'all' ||
      (filterType === 'disputed' && unrelatedCodes.length > 0) ||
      (filterType === 'related' && unrelatedCodes.length === 0);

    return matchesSearch && matchesType;
  });

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
      <div className="p-6 border-b border-gray-100">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">Claims Analysis</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Filter Claims
            </label>
            <select
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
              className="w-full rounded-lg border-gray-200 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="all">All Claims</option>
              <option value="disputed">Disputed Claims</option>
              <option value="related">Related Claims</option>
            </select>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Search Claims
            </label>
            <div className="relative">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search by provider or diagnosis code..."
                className="w-full rounded-lg border-gray-200 shadow-sm focus:border-blue-500 focus:ring-blue-500 pl-10"
              />
              <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
            </div>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-10"></th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Dates</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Provider</th>
                <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredClaims.map((claim, index) => {
                const key = `${claim.provider}-${claim.dates[0]}-${claim.dates[1]}`;
                const unrelatedCodes = unrelatedCodesMap[key] || [];
                const analysis = claimsAnalysis.find(ca => 
                  ca.row.provider_name_npi === claim.provider &&
                  ca.row.from_date === claim.dates[0] &&
                  ca.row.to_date === claim.dates[1]
                );
                
                return (
                  <ClaimRow
                    key={index}
                    claim={claim}
                    claimAnalysis={analysis}
                    unrelatedCodes={unrelatedCodes}
                    codeDescriptions={codeDescriptions}
                    chronologyEvents={chronologyEvents}
                    pdfUrl={pdfUrl}
                    pagesPerChunk={pagesPerChunk}
                    chronologyPdfUrl={chronologyPdfUrl}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const AppealLienClaimsDisplay = ({ content, pdfUrl, pagesPerChunk, analysisId, analysis }) => {
  // Add state for chronology data
  const [chronologyEvents, setChronologyEvents] = useState([]);
  const [chronologyLoading, setChronologyLoading] = useState(false);
  const [chronologyError, setChronologyError] = useState(null);
  // Add state for chronology PDF URL
  const [chronologyPdfUrl, setChronologyPdfUrl] = useState(null);
  
  // Extract all unique code descriptions into a centralized mapping
  const codeDescriptions = useMemo(() => {
    if (!content) return {};
    
    // Use the centralized mapping if available
    if (content.code_descriptions) {
      return content.code_descriptions;
    }
    
    // Otherwise, build it from the claims data
    if (!content.payment_data?.all_claims) return {};
    
    const descriptions = {};
    content.payment_data.all_claims.forEach(claim => {
      claim.codes.forEach(codeObj => {
        if (codeObj.code && codeObj.description) {
          // Extract just the description without the code in parentheses
          const desc = codeObj.description.replace(/^.*?\(|\)$/g, '');
          descriptions[codeObj.code] = desc;
        }
      });
    });
    
    // Add incident codes if available
    if (content.metadata?.data?.reported_diagnosis_codes) {
      content.metadata.data.reported_diagnosis_codes.forEach(code => {
        if (!descriptions[code]) {
          // Try to find the description in the claims data
          const foundClaim = content.payment_data?.all_claims?.find(claim => 
            claim.codes.some(c => c.code === code)
          );
          if (foundClaim) {
            const codeObj = foundClaim.codes.find(c => c.code === code);
            if (codeObj && codeObj.description) {
              descriptions[code] = codeObj.description.replace(/^.*?\(|\)$/g, '');
            }
          }
        }
      });
    }
    
    return descriptions;
  }, [content]);

  // Fetch chronology data when component mounts
  useEffect(() => {
    const fetchChronologyData = async () => {
      try {
        const chronologyId = analysis?.parameters?.chronology;
        if (!chronologyId) {
          console.log("No chronology ID found in analysis parameters");
          return;
        }
        
        setChronologyLoading(true);
        
        // 1. Fetch the chronology analysis data
        const chronologyData = await fetchAnalysis(chronologyId);
        
        // 2. Get the file_id from the chronology analysis
        if (chronologyData?.file_id) {
          try {
            // 3. Use fetchPdfUrl to get the URL from the file_id
            const chronologyPdf = await fetchPdfUrl(chronologyData.file_id);
            setChronologyPdfUrl(chronologyPdf);
          } catch (err) {
            console.error("Error fetching chronology PDF URL:", err);
          }
        }
        
        // 4. Set the chronology events if available
        if (chronologyData?.content?.all_events) {
          setChronologyEvents(chronologyData.content.all_events);
        } else {
          setChronologyError("No events found in chronology data");
        }
      } catch (error) {
        console.error("Error fetching chronology data:", error);
        setChronologyError(error.message);
      } finally {
        setChronologyLoading(false);
      }
    };
    
    if (analysis?.parameters?.chronology) {
      fetchChronologyData();
    }
  }, [analysis]);

  const handleReportDownload = async () => {
    try {
      if (!analysis?.report_path) {
        throw new Error('No report available for this analysis');
      }
      await downloadFile(analysis.report_path);
    } catch (error) {
      console.error('Error downloading report:', error);
      alert('Error downloading report. Please try again later.');
    }
  };

  if (!content) {
    return (
      <div className="p-8 text-center text-gray-500 bg-gray-50 rounded-lg">
        No analysis data available
      </div>
    );
  }

  // Modified error handling - show warning instead of preventing content display
  const hasRequiredData = content.metadata || content.payment_data || content.claims_analysis;
  
  // Only show a full error if no required data is available
  if (content.error && !hasRequiredData) {
    return (
      <div className="p-8 space-y-4">
        <div className="bg-red-50 border border-red-200 rounded-lg p-4">
          <h3 className="text-lg font-medium text-red-800 mb-2">Analysis Error</h3>
          <p className="text-red-700">{content.error.message}</p>
        </div>
        {content.partial_data && (
          <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
            <h3 className="text-lg font-medium text-yellow-800 mb-2">Partial Data Available</h3>
            <ul className="list-disc list-inside text-yellow-700">
              <li>Pages processed: {content.partial_data.info.num_pages_processed}</li>
              <li>Metadata found: {content.partial_data.info.metadata_found ? 'Yes' : 'No'}</li>
              <li>Table data found: {content.partial_data.info.table_data_found ? 'Yes' : 'No'}</li>
            </ul>
          </div>
        )}
      </div>
    );
  }

  const { metadata, payment_data, appeal_letter, claims_analysis } = content;

  return (
    <div className="max-w-6xl mx-auto p-6 space-y-8">
      {/* Show warning banner if there's an error but we still have data to display */}
      {content.error && (
        <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4 mb-6">
          <h3 className="text-lg font-medium text-yellow-800 mb-2">Analysis Warning</h3>
          <p className="text-yellow-700">{content.error.message}</p>
          <p className="text-yellow-700 mt-2 text-sm">Partial analysis results are displayed below.</p>
        </div>
      )}

      {/* Download Bar */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-3">
            <div className="h-10 w-10 rounded-full bg-blue-50 flex items-center justify-center">
              <Download className="h-5 w-5 text-blue-600" />
            </div>
            <div>
              <h3 className="font-medium text-gray-900">Medicare Appeal Report</h3>
              <p className="text-sm text-gray-500">Download the complete analysis</p>
            </div>
          </div>
          <button
            onClick={handleReportDownload}
            className="inline-flex items-center gap-2 px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            <Download className="h-4 w-4" />
            <span>Download Report</span>
          </button>
        </div>
      </div>

      {/* Case Information */}
      {metadata?.data && (
        <CaseMetadataCard 
          metadata={metadata.data} 
          codeDescriptions={content.code_descriptions?.data} 
        />
      )}

      {/* Claims Summary */}
      {payment_data?.stats && <CaseStatsSummary stats={payment_data.stats} />}

      {/* Appeal Letter */}
      {appeal_letter && <AppealLetterSection letter={appeal_letter.content} />}
      
      {/* Claims Analysis with debug count */}
      {payment_data?.all_claims && claims_analysis?.items && (
        <>
          <ClaimsAnalysisSection 
            claims={payment_data.all_claims}
            claimsAnalysis={claims_analysis.items}
            unrelatedClaims={payment_data.unrelated_claims || []}
            codeDescriptions={codeDescriptions}
            chronologyEvents={chronologyEvents}
            pdfUrl={pdfUrl}
            chronologyPdfUrl={chronologyPdfUrl}
            pagesPerChunk={pagesPerChunk}
          />
        </>
      )}
    </div>
  );
};

export default AppealLienClaimsDisplay; 